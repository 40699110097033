function mapOrderTypeToLabel(orderType) {
  if (orderType === 'SERVICE') {
    return 'Diensteauftrag';
  } else if (orderType === 'HOUSECONNECTION') {
    return 'Hausanschlussauftrag';
  } else if (orderType === 'HOUSECONNECTION_WITH_SERVICE') {
    return 'Hausanschluss- und Diensteauftrag';
  } else {
    return '';
  }
}

export default {
  mapOrderTypeToLabel
};

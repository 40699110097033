<template>
  <div>
    <v-card>
      <v-card-text>
        <v-card-title> Projekt und Baustatus ändern </v-card-title>
        <ProjectNameSelection
          :projectItem="projectItem"
          @updateProjektItem="updateProjektItem"
        />
      </v-card-text>
      <v-toolbar flat>
        <v-card-actions>
          <v-btn
            color="primary"
            :disabled="!projectItem || projectItem === undefined"
            @click="getFtthAddressesByProject"
          >
            Projekt laden
          </v-btn>
        </v-card-actions>
      </v-toolbar>
    </v-card>
    <v-row
      v-if="
        !changeRequestFlag &&
          !loadingPropertyStatus &&
          !loadingAddresses &&
          data &&
          data.length
      "
      style="padding-top: 15px"
    >
      <v-col>
        <v-tabs>
          <v-tab
            @change="
              () => {
                projectTabSelected = false;
                propertyTabSelected = true;
              }
            "
          >
            Liegenschaftsstatus
          </v-tab>
          <v-tab
            v-if="
              userGroups.includes('LgSpotBau') ||
                userGroups.includes('LgSpotBauLeitung') ||
                userGroups.includes('camunda-admin')
            "
            @change="
              () => {
                projectTabSelected = true;
                propertyTabSelected = false;
              }
            "
          >
            Projektstatus
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-row
      v-if="
        !changeRequestFlag &&
          !loadingPropertyStatus &&
          !loadingAddresses &&
          data &&
          data.length
      "
    >
      <v-col cols="6">
        <div v-if="propertyTabSelected">
          <v-row>
            <v-col>
              <v-checkbox
                v-if="
                  userGroups.includes('LgSpotBau') ||
                    userGroups.includes('LgSpotBauLeitung') ||
                    userGroups.includes('LgSpotAccess') ||
                    userGroups.includes('camunda-admin')
                "
                id="Hausanschluss gebaut"
                v-model="propertyState"
                multiple
                color="primary"
                value="Hausanschluss gebaut"
                @change="houseconnectionBuild = !houseconnectionBuild"
              >
                <template v-slot:label>
                  <v-icon :color="houseconnectionBuild ? '#6aa84f' : '#757575'">
                    mdi-home-alert
                  </v-icon>
                  Hausanschluss gebaut
                </template>
              </v-checkbox>
            </v-col>
            <v-col>
              <v-checkbox
                v-if="
                  userGroups.includes('LgSpotFieldservice') ||
                    userGroups.includes('LgSpotAccess') ||
                    userGroups.includes('LgSpotBauLeitung') ||
                    userGroups.includes('camunda-admin')
                "
                id="Haus gepatched"
                v-model="propertyState"
                multiple
                color="primary"
                value="Haus gepatched"
                @change="housePatched = !housePatched"
              >
                <template v-slot:label>
                  <v-icon :color="housePatched ? '#6aa84f' : '#757575'">
                    mdi-home-assistant
                  </v-icon>
                  Haus gepatched
                </template>
              </v-checkbox>
            </v-col>
            <v-col>
              <v-checkbox
                v-if="
                  userGroups.includes('LgSpotFieldservice') ||
                    userGroups.includes('LgSpotAccess') ||
                    userGroups.includes('camunda-admin')
                "
                id="POP Online"
                v-model="propertyState"
                multiple
                color="primary"
                value="POP Online"
                @change="popOnline = !popOnline"
              >
                <template v-slot:label>
                  <v-icon :color="popOnline ? '#6aa84f' : '#757575'">
                    mdi-transit-connection-variant
                  </v-icon>
                  POP Online
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
        </div>
        <div v-if="projectTabSelected">
          <v-row>
            <v-col
              cols="1"
              align-self="center"
              style="max-width: 4.333%"
            >
              <v-icon> mdi-home-group </v-icon>
            </v-col>
            <v-col>
              <v-combobox
                v-if="projectTabSelected"
                v-model="newProjectState"
                :items="projectStates"
                item-disabled="disable"
                item-text="name"
                color="primary"
                label="Projektstatus wählen..."
              />
            </v-col>
            <v-col>
              <DatePicker
                label="Datum für den Baustart"
                :value="showConstructionStartDate"
                disableWeekend
                disableHolidays
                :disabled="
                  newProjectState.name !=
                    projectStateConstants.PROJECT_CONSTRUCTION_STARTED.value
                "
                @input="updateConstructionStartDate($event)"
              />
            </v-col>
          </v-row>
        </div>
        <DetailMap
          :data="filteredData"
          :optionalData="propertiesWithoutProject"
          :projectId="projectItem.projektId"
          height="539px"
        />

        <div
          v-if="
            propertiesWithoutProject != undefined &&
              propertiesWithoutProject !== null &&
              propertiesWithoutProject.length > 0
          "
        >
          <v-alert
            dense
            type="error"
            class="alert-margin"
          >
            Einige Adressen mit einem Dienstauftrag haben keine Projektzuordnung
            <v-btn icon @click="toggleTable = !toggleTable">
              <v-icon v-if="toggleTable">
                mdi-chevron-up
              </v-icon>
              <v-icon v-else>
                mdi-chevron-down
              </v-icon>
            </v-btn>
          </v-alert>
          <v-text-field
            v-if="toggleTable"
            v-model="searchTextWithoutProject"
            append-icon="mdi-magnify"
            label="Suche"
            single-line
            hide-details
            clearable
          />
          <v-data-table
            v-if="toggleTable"
            style="background: rgba(255, 0, 0, 0.05)"
            :headers="propertiesWithoutProjectHeaders"
            :items="propertiesWithoutProject"
            :item-class="row_classes"
            multi-sort
            :search="searchTextWithoutProject"
            class="elevation-1 without-project-table"
          >
            <template v-slot:[`item.geo`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-if="item.geo == undefined || item.geo === null"
                    v-bind="attrs"
                    color="#8800b8"
                    v-on="on"
                  >
                    mdi-map-marker-off-outline
                  </v-icon>
                  <v-icon
                    v-else-if="item.hasDifferentProject === true"
                    v-bind="attrs"
                    color="#bdbd00"
                    v-on="on"
                  >
                    mdi-map-marker-alert-outline
                  </v-icon>
                  <v-icon
                    v-else
                    v-bind="attrs"
                    color="red"
                    v-on="on"
                  >
                    mdi-map-marker-check-outline
                  </v-icon>
                </template>
                <span v-if="item.geo == undefined || item.geo === null">
                  Keine Ags27 ID vorhanden
                </span>
                <span v-else-if="item.hasDifferentProject === true">
                  Liegenschaft ist dem Projekt
                  {{ item.projectId }} zugeordnet</span>
                <span v-else>Projektzuordnung fehlt</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.address.street`]="{ item }">
              {{ item.address.street }} {{ item.address.houseNumber
              }}{{ item.address.houseNumberSupplement }}
            </template>
            <template v-slot:[`item.address.firstName`]="{ item }">
              {{ item.address.lastName }}, {{ item.address.firstName }}
            </template>
            <template v-slot:[`item.ftthProductDescription`]="props">
              <template v-if="props.item.ftthProductDescription.length === 1">
                {{ props.item.ftthProductDescription[0] }}
              </template>
              <template v-else>
                <ul
                  v-for="(description, index) in props.item
                    .ftthProductDescription"
                  :key="index"
                >
                  <li>{{ description }}</li>
                </ul>
              </template>
            </template>
          </v-data-table>
        </div>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="searchText"
          append-icon="mdi-magnify"
          label="Suche"
          single-line
          hide-details
          clearable
        />
        <v-data-table
          ref="dataTable"
          v-model="checkedAddresses"
          :headers="tableHeaders"
          :items="data"
          item-key="ags27Id"
          :item-class="row_classes"
          :single-expand="false"
          :show-expand="false"
          :expanded.sync="expandedRows"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :search="searchText"
          :show-select="propertyTabSelected"
          checkbox-color="primary"
          class="elevation-1"
          :items-per-page="25"
          :footer-props="{
            showFirstLastPage: true,
            showCurrentPage: true,
            'items-per-page-text': 'Zeilen pro Seite:',
            'items-per-page-options': [10, 25, 50, 100, -1],
            'page-text': '{0}-{1} von {2}'
          }"
          @toggle-select-all="
            () => {
              !selectAllToggle
                ? (checkedAddresses = filteredData)
                : (checkedAddresses = []);
              selectAllToggle = !selectAllToggle;
            }
          "
          @current-items="getFiltered"
          @click:row="rowClicked"
          @input="getSelectedItems($event)"
        >
          <template v-if="propertyTabSelected" v-slot:[`footer.page-text`]>
            Aktuell {{ checkedAddresses.length }} Adressen ausgewählt von
            {{ data.length }}
          </template>
          <template v-slot:[`item.address.houseNumber`]="{ item }">
            {{ item.address.houseNumber
            }}{{ item.address.houseNumberSupplement }}
          </template>
          <template v-slot:[`item.constructionState`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  :color="item.houseConnectionBuild ? '#6aa84f' : '#9b3232'"
                  v-on="on"
                >
                  mdi-home-alert
                </v-icon>
              </template>
              <span v-if="item.houseConnectionBuild">Hausanschluss gebaut</span>
              <span v-else>Hausanschluss nicht gebaut</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  :color="item.houseConnectionPatched ? '#6aa84f' : '#9b3232'"
                  v-on="on"
                >
                  mdi-home-assistant
                </v-icon>
              </template>
              <span v-if="item.houseConnectionPatched">Haus gepatched</span>
              <span v-else>Haus nicht gepatched</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  :color="item.popOnline ? '#6aa84f' : '#9b3232'"
                  v-on="on"
                >
                  mdi-transit-connection-variant
                </v-icon>
              </template>
              <span v-if="item.popOnline">POP Online</span>
              <span v-else>POP nicht Online</span>
            </v-tooltip>
          </template>
          <template v-slot:expanded-item="{ item }">
            <td colspan="7" class="spot-expanded-table-padding">
              <v-data-table
                :headers="expandedTableHeaders"
                :items="item.contractList"
                :hide-default-footer="true"
                class="spot-expanded-table-shadow"
              >
                <template v-slot:[`item.ftth`]="props">
                  <template v-if="props.item.ftth && props.item.hc">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">
                          mdi-home-plus
                        </v-icon>
                      </template>
                      <span>Dienstvertrag mit Hausanschluss</span>
                    </v-tooltip>
                  </template>
                  <template v-if="props.item.ftth && !props.item.hc">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">
                          mdi-web
                        </v-icon>
                      </template>
                      <span>Dienstvertrag</span>
                    </v-tooltip>
                  </template>
                  <template v-if="props.item.hc && !props.item.ftth">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">
                          mdi-home-city
                        </v-icon>
                      </template>
                      <span>Hausanschluss</span>
                    </v-tooltip>
                  </template>
                </template>
                <template v-slot:[`item.productDescription`]="props">
                  <template v-if="props.item.productDescription.length === 1">
                    {{ props.item.productDescription[0] }}
                  </template>
                  <template v-else>
                    <ul
                      v-for="(description, index) in props.item
                        .productDescription"
                      :key="index"
                    >
                      <li>{{ description }}</li>
                    </ul>
                  </template>
                </template>
              </v-data-table>
            </td>
          </template>
        </v-data-table>
        <div v-if="data && data.length" class="buttons-wrapper">
          <v-btn
            v-if="propertyTabSelected"
            :disabled="
              checkedAddresses.length === 0 || propertyState.length <= 0
            "
            color="primary"
            large
            @click="changeRequestOverview"
          >
            Status ändern
          </v-btn>
          <v-btn
            v-if="projectTabSelected"
            :disabled="
              newProjectState == undefined ||
                newProjectState.name === currentProjectState.name ||
                (newProjectState.name ===
                  projectStateConstants.PROJECT_CONSTRUCTION_STARTED.value &&
                  (constructionStartDate == null ||
                    constructionStartDate === undefined))
            "
            color="primary"
            large
            @click="createProjectChangeRequest"
          >
            Status ändern
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <template
      v-if="
        checkedAddresses &&
          checkedAddresses.length &&
          changeRequestFlag &&
          propertyTabSelected
      "
    >
      <v-data-table
        :caption="caption"
        :headers="tableHeaders"
        :items="checkedAddresses"
        item-key="ags27Id"
        :item-class="row_classes"
        :single-expand="false"
        :show-expand="false"
        :search="searchText"
        :expanded.sync="expandedRows"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        class="elevation-1"
        :items-per-page="25"
        :footer-props="{
          showFirstLastPage: true,
          showCurrentPage: true,
          'items-per-page-text': 'Zeilen pro Seite:',
          'items-per-page-options': [10, 25, 50, 100, -1]
        }"
        @click:row="rowClicked"
      >
        <template v-slot:[`footer.page-text`]>
          {{ checkedAddresses.length }} von {{ data.length }} Adressen
        </template>
        <template v-slot:[`item.address.houseNumber`]="{ item }">
          {{ item.address.houseNumber }}{{ item.address.houseNumberSupplement }}
        </template>
        <template v-slot:[`item.constructionState`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                :color="item.houseConnectionBuild ? '#6aa84f' : '#9b3232'"
                v-on="on"
              >
                mdi-home-alert
              </v-icon>
            </template>
            <span v-if="item.houseConnectionBuild">Hausanschluss gebaut</span>
            <span v-else>Hausanschluss nicht gebaut</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                :color="item.houseConnectionPatched ? '#6aa84f' : '#9b3232'"
                v-on="on"
              >
                mdi-home-assistant
              </v-icon>
            </template>
            <span v-if="item.houseConnectionPatched">Haus gepatched</span>
            <span v-else>Haus nicht gepatched</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                :color="item.popOnline ? '#6aa84f' : '#9b3232'"
                v-on="on"
              >
                mdi-transit-connection-variant
              </v-icon>
            </template>
            <span v-if="item.popOnline">POP Online</span>
            <span v-else>POP nicht Online</span>
          </v-tooltip>
        </template>
        <template v-slot:expanded-item="{ item }">
          <td colspan="7" class="spot-expanded-table-padding">
            <v-data-table
              :headers="expandedTableHeaders"
              :items="item.contractList"
              :hide-default-footer="true"
              class="spot-expanded-table-shadow"
            >
              <template v-slot:[`item.ftth`]="props">
                <template v-if="rops.item.ftth && props.item.hc">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on">
                        mdi-home-plus
                      </v-icon>
                    </template>
                    <span>Dienstvertrag mit Hausanschluss</span>
                  </v-tooltip>
                </template>
                <template v-if="props.item.ftth && !props.item.hc">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on">
                        mdi-web
                      </v-icon>
                    </template>
                    <span>Dienstvertrag</span>
                  </v-tooltip>
                </template>
                <template v-if="props.item.hc && !props.item.ftth">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on">
                        mdi-home-city
                      </v-icon>
                    </template>
                    <span>Hausanschluss</span>
                  </v-tooltip>
                </template>
              </template>
              <template v-slot:[`item.productDescription`]="props">
                <template v-if="props.item.productDescription.length === 1">
                  {{ props.item.productDescription[0] }}
                </template>
                <template v-else>
                  <ul
                    v-for="(description, index) in props.item
                      .productDescription"
                    :key="index"
                  >
                    <li>{{ description }}</li>
                  </ul>
                </template>
              </template>
            </v-data-table>
          </td>
        </template>
      </v-data-table>
      <div class="buttons-wrapper">
        <v-btn
          color="primary"
          style="position: absolute"
          @click="changeRequestOverview"
        >
          Zurück
        </v-btn>
        <v-btn color="primary" @click="createPropertyChangeRequest()">
          Abschließen
        </v-btn>
      </div>
    </template>
    <v-overlay v-if="loadingPropertyStatus || loadingAddresses">
      <v-progress-circular
        :size="120"
        color="primary"
        indeterminate
      >
      </v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import { HTTP } from '@/main/httpClient.js';
import ProjectNameSelection from '@/components/statistic/ProjectNameSelection.vue';
import NotificationObject from '@/main/NotificationObject.js';
import DetailMap from '@/components/constructionProjects/DetailMap.vue';
import ErrorMessageBuilder from '@/util/ErrorMessageBuilder.js';
import DatePicker from '@/components/elements/DatePicker.vue';
export default {
  name: 'ConstructionProjectStates',
  components: {
    ProjectNameSelection,
    DetailMap,
    DatePicker
  },
  data: () => ({
    toggleTable: true,
    constructionStartDate: undefined,
    currentProjectStartDate: undefined,
    propertyTabSelected: true,
    projectTabSelected: false,
    projectItem: undefined,
    loadingAddresses: undefined,
    loadingPropertyStatus: undefined,
    changeRequestFlag: false,
    selectAllToggle: false,
    data: undefined,
    propertiesWithoutProject: undefined,
    filteredData: undefined,
    searchText: '',
    searchTextWithoutProject: '',
    newProjectState: undefined,
    currentProjectState: {},
    propertyState: [],
    houseconnectionBuild: false,
    housePatched: false,
    popOnline: false,
    projectCity: undefined,
    projectDistrict: undefined,
    ags27Ids: [],
    sortBy: 'dateProcessStart',
    expandedRows: [],
    selected: [],
    checkedAddresses: [],
    sortDesc: true,
    projectState: null,
    projectStates: [],
    projectStatusIdMap: new Map([]),
    tableHeaders: [
      { text: 'PLZ', value: 'address.zipCode', width: '80px' },
      { text: 'Ortsteil', value: 'address.district' },
      { text: 'Straße', value: 'address.street' },
      { text: 'Hausnummer', value: 'address.houseNumber' },
      {
        text: 'Liegenschaftsstatus',
        align: 'start',
        value: 'constructionState'
      },
      { text: 'FTTH-Verträge', value: 'contractList.length' }
    ],
    expandedTableHeaders: [
      { text: '' },
      { text: 'Typ', value: 'ftth' },
      { text: 'Kundennummer', value: 'customerId' },
      { text: 'Vorname', value: 'firstname' },
      { text: 'Nachname', value: 'lastname' },
      { text: 'Vertrag', value: 'contractId' },
      { text: 'Produkt', value: 'productDescription' }
    ],
    propertiesWithoutProjectHeaders: [
      { text: '', value: 'geo' },
      { text: 'Kundennr.', value: 'customerId', width: '80px' },
      { text: 'Name', value: 'address.firstName' },
      { text: 'PLZ', value: 'address.zipCode', width: '50px' },
      { text: 'Ortsteil', value: 'address.district' },
      { text: 'Straße', value: 'address.street' },
      { text: 'Vertrag', value: 'ftthContractId' },
      { text: 'Produkt', value: 'ftthProductDescription' }
    ]
  }),
  computed: {
    showConstructionStartDate() {
      return this.newProjectState.name ===
        this.projectStateConstants.PROJECT_CONSTRUCTION_STARTED.value
        ? this.constructionStartDate
        : undefined;
    },
    caption() {
      return `Adressen deren Baustatus zu ${this.propertyState.join(
        ', '
      )} geändert werden soll`;
    },
    username() {
      return this.$auth.user().userName;
    },
    userGroups() {
      return this.$auth.user().userRoles;
    }
  },
  watch: {
    projectItem: function () {
      this.changeRequestFlag = false;
      this.clear();
    },
    currentProjectState: {
      handler: function (newValue, oldValue) {
        if (newValue.name) {
          this.findPossibleProjectStates();
        }
      },
      deep: true
    }
  },
  created() {
    this.projectStateConstants = {
      NOT_SET: {
        value: 'Nicht gesetzt'
      },
      PROJECT_PLANNED: {
        value: 'Projekt geplant'
      },
      PROJECT_READY: {
        value: 'Projekt abgeschlossen'
      },
      PROJECT_CANCELED: {
        value: 'Projekt abgebrochen'
      },
      PROJECT_CONSTRUCTION_STARTED: {
        value: 'Bau gestartet'
      }
    };
  },
  methods: {
    updateConstructionStartDate(newDate) {
      this.constructionStartDate = newDate;
    },
    rowClicked(item, row) {
      row.expand(!row.isExpanded);
    },
    showMessage: function (type, description) {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, description)
      );
    },
    row_classes(item) {
      if (item.match === 1) {
        return 'highlighted-row';
      }
    },
    updateProjektItem(projectItem) {
      this.projectItem = projectItem;
    },
    findPossibleProjectStates() {
      this.projectStates = [];
      // Admin is allowed to change to any state at any time
      if (this.userGroups.includes('camunda-admin')) {
        this.projectStates.push({
          name: this.projectStateConstants.NOT_SET.value,
          disable: true
        });
        this.projectStates.push({
          name: this.projectStateConstants.PROJECT_PLANNED.value,
          disable: false
        });
        this.projectStates.push({
          name: this.projectStateConstants.PROJECT_CONSTRUCTION_STARTED.value,
          disable: false
        });
        this.projectStates.push({
          name: this.projectStateConstants.PROJECT_READY.value,
          disable: false
        });
        this.projectStates.push({
          name: this.projectStateConstants.PROJECT_CANCELED.value,
          disable: false
        });
      } else {
        this.projectStates.push({
          name: this.projectStateConstants.NOT_SET.value,
          disable: true
        });
        this.projectStates.push({
          name: this.projectStateConstants.PROJECT_PLANNED.value,
          disable:
            this.currentProjectState.name ===
              this.projectStateConstants.PROJECT_PLANNED.value ||
            this.currentProjectState.name ===
              this.projectStateConstants.PROJECT_CONSTRUCTION_STARTED.value ||
            this.currentProjectState.name ===
              this.projectStateConstants.PROJECT_READY.value ||
            this.currentProjectState.name ===
              this.projectStateConstants.PROJECT_CANCELED.value
        });
        this.projectStates.push({
          name: this.projectStateConstants.PROJECT_CONSTRUCTION_STARTED.value,
          disable:
            this.currentProjectState.name ===
              this.projectStateConstants.PROJECT_CONSTRUCTION_STARTED.value ||
            this.currentProjectState.name ===
              this.projectStateConstants.PROJECT_READY.value ||
            this.currentProjectState.name ===
              this.projectStateConstants.PROJECT_CANCELED.value
        });
        this.projectStates.push({
          name: this.projectStateConstants.PROJECT_READY.value,
          disable:
            this.currentProjectState.name ===
              this.projectStateConstants.PROJECT_READY.value ||
            this.currentProjectState.name ===
              this.projectStateConstants.PROJECT_CANCELED.value
        });
        this.projectStates.push({
          name: this.projectStateConstants.PROJECT_CANCELED.value,
          disable: false
        });
      }
    },
    getConstructionStartDate(currentProjectStartDate) {
      var dateString;
      if (
        currentProjectStartDate !== '' &&
        currentProjectStartDate != null &&
        currentProjectStartDate !== undefined
      ) {
        const dateInMilliSec = Date.parse(currentProjectStartDate);
        const date = new Date(dateInMilliSec);
        dateString =
          date.getFullYear() +
          '-' +
          String(date.getMonth() + 1).padStart(2, '0') +
          '-' +
          String(date.getDate()).padStart(2, '0');
      }
      return dateString;
    },
    getCurrentProjectState() {
      HTTP.get(
        `past/projects?where[projektId][equals]=${this.projectItem.projektId}`
      )
        .then((result) => {
          this.currentProjectStartDate = result.data[0].startDate;
          this.constructionStartDate = this.getConstructionStartDate(
            this.currentProjectStartDate
          );
          this.currentProjectState = {
            name:
              result.data[0].projectStatus.description !== null ||
              result.data[0].projectStatus.description !== ''
                ? result.data[0].projectStatus.description
                : this.projectStateConstants.NOT_SET.value,
            disable: true
          };
          this.newProjectState = this.currentProjectState;
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              `Der Projektstatus von Projekt ${this.projectItem.projektId} konnte nicht ermittelt werden.`
            )
          )
        );
    },
    getFtthAddressesByProject() {
      this.loadingAddresses = true;
      this.clear();
      HTTP.get(
        `/projectConstructionState/addresses/projectBulk?projectId=${this.projectItem.projektId}`
      )
        .then(({ data }) => {
          this.projectState = data.projectState;
          this.data = data.propertyConstructionStateDtoList;
          this.propertiesWithoutProject = data.propertyWithoutProjectDtos;
          this.filteredData = this.data;
          this.projectCity = this.data[0].address.city;
          this.projectDistrict = this.data[0].address.district;
          this.data.forEach((element) => {
            this.ags27Ids.push(element.ags27Id);
          });
          this.getAllPropertyStatusByAgs27IdBulk();
          this.getCurrentProjectState();
          this.getProjectStatuses();
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Die Adressen konnten nicht geladen werden'
            )
          )
        )
        .finally(() => {
          this.loadingAddresses = false;
        });
    },
    getAllPropertyStatusByAgs27IdBulk() {
      this.loadingPropertyStatus = true;
      HTTP.post('/order/propertyStatus', this.ags27Ids)
        .then((result) => {
          this.propertyResults = result.data.propertyResults;
          this.propertyResults.forEach((property) => {
            this.data.forEach((entry) => {
              if (entry.ags27Id === property.ags27) {
                entry.houseConnectionBuild = property.houseConnectionBuilt;
                entry.houseConnectionPatched = property.houseConnectionPatched;
                entry.popOnline = property.popOnline;
              }
            });
          });
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Der Liegenschaftsstatus der Adressen konnte nicht geladen werden'
            )
          )
        )
        .finally(() => {
          this.loadingPropertyStatus = false;
        });
    },
    changeRequestOverview() {
      this.changeRequestFlag = !this.changeRequestFlag;
      this.expandedRows = [];
    },
    getSelectedItems(values) {
      this.checkedAddresses = values.map(function (value) {
        return value;
      });
    },
    getFiltered() {
      if (this.$refs.dataTable) {
        this.filteredData = this.$refs.dataTable.$children[0].filteredItems;
      }
    },
    createPropertyChangeRequest() {
      const today = new Date().toISOString();
      const propertyConstructionStateDtos = this.checkedAddresses;
      const projectConstructionStateChangeDataDto = {
        user: this.username,
        userGroups: this.userGroups,
        houseconnectionBuilt: this.houseconnectionBuild,
        housePatched: this.housePatched,
        popOnline: this.popOnline,
        projectId: this.projectItem.projektId,
        houseConnectionBuiltDate: this.houseconnectionBuild ? today : null,
        houseConnectionPatchedDate: this.housePatched ? today : null,
        popOnlineDate: this.popOnline ? today : null
      };
      HTTP.post('/projectConstructionState/changePropertyState', {
        projectConstructionStateChangeDataDto,
        propertyConstructionStateDtos
      })
        .then(() => {
          this.clear();
          this.changeRequestFlag = !this.changeRequestFlag;
          this.showMessage(
            'success',
            'Die Liegenschaftsstatusänderungen wurden zur Überprüfung freigegeben.'
          );
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Etwas ist schief gelaufen'
            )
          )
        );
    },
    getProjectStatuses() {
      HTTP.get('past/projectStatuses')
        .then((response) => {
          response.data.forEach((dataEntry) => {
            this.projectStatusIdMap.set(dataEntry.description, dataEntry);
          });
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Die Projektstatus IDs konnten nicht geladen werden'
            )
          )
        );
    },
    createProjectChangeRequest() {
      if (
        this.newProjectState.name !== this.projectStateConstants.NOT_SET.value
      ) {
        console.log(this.newProjectState, this.oldProjectState);
        const oldProjectState = this.projectStatusIdMap.get(
          this.projectState
        );
        const newProjectState = this.projectStatusIdMap.get(
          this.newProjectState.name
        );
        const propertyConstructionStateDtos = this.data;
        const projectConstructionStateChangeDataDto = {
          user: this.username,
          userGroups: this.userGroups,
          oldProjectStateDto: oldProjectState,
          newProjectStateDto: newProjectState,
          projectId: this.projectItem.projektId,
          constructionStart:
            this.newProjectState.name ===
            this.projectStateConstants.PROJECT_CONSTRUCTION_STARTED.value
              ? new Date(this.constructionStartDate).toISOString()
              : this.currentProjectStartDate,
          city: this.projectCity,
          district: this.projectDistrict
        };
        HTTP.post('/projectConstructionState/changeProjectState', {
          projectConstructionStateChangeDataDto,
          propertyConstructionStateDtos
        })
          .then(() => {
            this.clear();
            this.showMessage(
              'success',
              'Die Projektstatusänderung wurde zur Überprüfung freigegeben.'
            );
          })
          .catch((err) =>
            this.showMessage(
              'error',
              ErrorMessageBuilder.buildErrorMessage(
                err.response.data,
                'Etwas ist schief gelaufen'
              )
            )
          );
      } else {
        this.showMessage(
          'error',
          ErrorMessageBuilder.buildErrorMessage([], 'Ungültiger Projektstatus')
        );
      }
    },
    clear() {
      this.data = [];
      this.checkedAddresses = [];
      this.ags27Ids = [];
      this.filteredData = [];
      this.expandedRows = [];
      this.propertyState = [];
      this.popOnline = false;
      this.housePatched = false;
      this.houseconnectionBuild = false;
      this.newProjectState = undefined;
      this.currentProjectState = {};
      this.projectTabSelected = false;
      this.propertyTabSelected = true;
    }
  }
};
</script>
<style lang="scss" scoped>
.alert-margin {
  margin-top: 2px;
}
.without-project-table {
  margin-bottom: 50px;
}
.buttons-wrapper {
  display: grid;
  grid-template-columns: auto max-content;
  grid-gap: var(--goe-spacing-1);
  margin-top: 10px;
  margin-bottom: 25px;
}
::v-deep .v-data-footer__pagination {
  position: absolute;
}
.buttons-wrapper .v-btn {
  width: max-content;
  margin-left: auto;
  margin-right: var(--goe-spacing-0);
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 0px;
}
.spot-expanded-table-shadow {
  background-color: rgba(0, 0, 0, 0);
}
</style>

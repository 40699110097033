<template>
  <div><PortingProcessDashboard /></div>
</template>

<script>
import PortingProcessDashboard from '@/dashboard/PortingProcessDashboard.vue';
export default {
  name: 'Dashboard',
  components: { PortingProcessDashboard },
  data: () => ({}),
  methods: {}
};
</script>
<style lang="scss" scoped></style>

<template>
  <div>
    <h3>Neues Projekt</h3>
    <v-row>
      <!-- <v-col md="6">
        <project-map />
      </v-col> -->
      <v-col md="12">
        <v-form>
          <v-row>
            <v-col md="6">
              <v-card>
                <v-card-text>
                  <h4>Allgemeine Angaben</h4>
                  <v-text-field
                    v-model="project.name"
                    :rules="[requiredRule]"
                    label="Projektname"
                    required
                    filled
                    dense
                  ></v-text-field>
                  <v-row>
                    <v-col md="6">
                      <v-autocomplete
                        v-model="project.projectStatusId"
                        :items="projectStatuses"
                        item-text="description"
                        item-value="id"
                        key-name="id"
                        label="Projektstatus"
                        filled
                        dense
                      >
                        <template v-slot:selection="{ item }">
                          <v-chip
                            :color="item.color"
                            label
                            small
                          >
                            {{ item.description }}
                          </v-chip>
                        </template>
                        <template v-slot:item="{ item }">
                          <v-chip
                            :color="item.color"
                            label
                            small
                          >
                            {{ item.description }}
                          </v-chip>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col md="6">
                      <v-text-field
                        v-model="project.ktr"
                        label="Kostenträger-Nr."
                        required
                        filled
                        dense
                        counter
                        maxlength="7"
                        :rules="[numericRule]"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-card>
                <v-card-text>
                  <h4>Vertriebsinformationen</h4>
                  <v-row>
                    <v-col md="6">
                      <v-autocomplete
                        v-model="project.tariffModelId"
                        :items="tariffModels"
                        item-text="name"
                        item-value="id"
                        key-name="id"
                        label="Tarifmodell"
                        filled
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <v-col md="6">
                      <v-autocomplete
                        v-model="project.distributionStatusId"
                        :items="distributionStatuses"
                        item-text="description"
                        item-value="id"
                        key-name="id"
                        label="Vertriebsstatus"
                        filled
                        dense
                      >
                        <template v-slot:selection="{ item }">
                          <v-chip
                            :color="item.color"
                            label
                            small
                          >
                            {{ item.description }}
                          </v-chip>
                        </template>
                        <template v-slot:item="{ item }">
                          <v-chip
                            :color="item.color"
                            label
                            small
                          >
                            {{ item.description }}
                          </v-chip>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-card>
                <v-card-text>
                  <h4>
                    Integration monday
                    <small>
                      ({{ monday.name }} | {{ monday.slug }}.monday.com)
                    </small>
                  </h4>
                  <v-row>
                    <v-col md="6">
                      <v-text-field
                        :value="monday.board.name"
                        label="Boardname"
                        disabled
                        filled
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="6">
                      <v-select
                        v-model="monday.board.selectedGroup"
                        :items="monday.board.groups"
                        item-text="title"
                        item-value="id"
                        label="Gruppe"
                        filled
                        dense
                      ></v-select>
                    </v-col>
                    <!-- <v-col md="2">
                  <v-btn
                    :loading="loading"
                    :disabled="loading"
                    @click="testSubmit"
                  ></v-btn>
                </v-col> -->
                    <!-- <v-col md="4">
                  <v-select
                    v-model="monday.board.projectIdId"
                    :items="monday.board.columns"
                    item-text="title"
                    item-value="id"
                    label="Projekt ID Feldname"
                    filled
                    dense
                  ></v-select>
                </v-col> -->
                    <v-col md="6">
                      <v-select
                        v-model="monday.board.selectedGroup"
                        :items="monday.board.columns"
                        item-text="title"
                        item-value="id"
                        label="Gruppe"
                        filled
                        dense
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col md="6">
              <v-card>
                <v-card-text>
                  <h4>Geoinformationen</h4>
                  <AddressDistrictSelection
                    :city="project.city"
                    :district="project.district"
                    :filled="true"
                    :dense="true"
                    :no-icons="true"
                    :full-width="true"
                    @updateCity="updateCity"
                    @updateDistrict="updateDistrict"
                  />
                  <v-row>
                    <v-col md="6">
                      <v-autocomplete
                        v-model="project.county"
                        :items="counties"
                        item-text="name"
                        item-value="name"
                        key-name="id"
                        label="Landkreis"
                        filled
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <v-col md="6">
                      <v-text-field
                        v-model="project.federalState"
                        :rules="[requiredRule]"
                        label="Bundesland"
                        required
                        disabled
                        filled
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-alert
                    v-if="newPolygon && newPolygon.length"
                    type="success"
                    text
                  >
                    {{ newPolygon.length }} Polygon<span v-if="newPolygon.length > 1">e</span> erzeugt
                  </v-alert>
                  <v-btn color="primary" @click="polygonDialogVisible = true, polygonSwitch = 'new'">
                    Neues Polygon
                  </v-btn>
                  <v-btn
                    color="primary"
                    class="ml-2"
                    @click="polygonDialogVisible = true, polygonSwitch = 'select'"
                  >
                    bestehendes Ausbaugebiet / Polygon mappen
                  </v-btn>
                  <br />
                </v-card-text>
              </v-card>
              <v-card>
                <v-card-text>
                  <h4>Hardwareversand</h4>
                  <v-row>
                    <v-col md="6">
                      <v-autocomplete
                        v-model="project.shippingMethodId"
                        :items="shippingMethods"
                        item-text="description"
                        item-value="id"
                        key-name="id"
                        label="Versandart"
                        filled
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <v-col md="6">
                      <v-switch
                        v-model="project.dispatchOnt"
                        label="ONT versenden"
                        :disabled="!project.shippingMethodId"
                      ></v-switch>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <!-- <pre v-if="newPolygon && newPolygon[0] && newPolygon[0].geometry && newPolygon[0].geometry">{{ JSON.stringify(newPolygon[0].geometry) }}</pre> -->
          <v-btn color="success" @click="saveProject">
            Projekt speichern
          </v-btn>
          <br />
          <br />
        </v-form>
      </v-col>
    </v-row>
    <v-dialog
      v-model="polygonDialogVisible"
      transition="dialog-bottom-transition"
      max-width="1200"
    >
      <v-card style="margin-bottom: 0;">
        <v-toolbar
          color="primary"
          dark
        >
          <template v-if="polygonSwitch === 'new'">
            Neues Projekt-Polygon
          </template>
          <template v-else>
            Bestehendes Polygon auswählen
          </template>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click="polygonDialogVisible = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <div class="my-2">
            <template v-if="polygonSwitch === 'new'">
              <project-map @updatePolygon="updatePolygon" />
            </template>
            <template v-else>
              Aktuell sind keine unzugeordnete Polygone vorhanden.
            </template>
            <br />
            <v-btn
              :color="polygonSwitch === 'new' ? 'success' : 'primary'"
              class="float-right"
              @click="polygonDialogVisible = false"
            >
              <v-icon>mdi-check</v-icon>
              OK
            </v-btn>
            <!-- <v-btn @click="saveProjectPolygon">
              speichern
            </v-btn> -->
            <br />
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import mondayApi from '@/main/mondayApi.js';
// import { print } from 'graphql/language/printer';
import { fetchData, insertItem } from '@/graphql/mondayQuerys.gql';
import NotificationObject from '@/main/NotificationObject.js';
// import _ from 'lodash';
import ProjectMap from '@/components/constructionProjects/ProjectMap.vue';
import { HTTP } from '../../main/httpClient';

export default {
  name: 'NewProject',
  components: {
    ProjectMap,
    AddressDistrictSelection: () =>
      import('@/components/statistic/AddressDistrictSelection.vue')
  },
  data: () => ({
    loading: false,
    polygonDialogVisible: false,
    newPolygon: undefined,
    project: {
      name: undefined,
      city: undefined,
      district: undefined,
      county: undefined,
      federalState: undefined,
      ktr: undefined,
      tariffModelId: undefined,
      contactPerson: undefined,
      constructionManager: undefined,
      distributionArea: undefined,
      distributionStatus: undefined,
      financialProject: undefined,
      localSalesman: undefined,
      projectArea: undefined,
      projectStatusId: undefined,
      distributionStatusId: undefined,
      subcontractorBackbone: undefined,
      subcontractorCableInstallation: undefined,
      subcontractorHouseConnection: undefined,
      subcontractorLocalNetwork: undefined,
      shippingMethodId: undefined,
      dispatchOnt: false
    },
    contactPersons: [],
    constructionManagers: [],
    distributionAreas: [],
    distributionStatuses: [],
    projectStatuses: [],
    counties: [],
    tariffModels: [],
    shippingMethods: [],
    financialProjects: [],
    localSalesmans: [],
    projectAreas: [],
    companies: [],
    polygonSwitch: undefined,
    goetelAreas: undefined,
    monday: {
      alert: false,
      name: undefined,
      slug: undefined,
      entrieUrl: undefined,
      board: {
        id: 1125990819,
        name: undefined,
        groups: [],
        columns: [],
        selectedGroup: 'topics',
        projectIdId: 'zahlen4'
      }
    }
  }),
  computed: {
    multiPolygon() {
      let returnObject = {};
      const polygonArray = [];
      if (this.newPolygon && this.newPolygon[0] && this.newPolygon[0].geometry) {
        this.newPolygon.forEach((item) => {
          if (item.geometry && item.geometry.coordinates) {
            polygonArray.push(item.geometry.coordinates);
          }
        });
      }
      returnObject = {
        type: 'Multipolygon',
        coordinates: polygonArray
      }
      return returnObject;
    }
  },
  watch: {
    polygonSwitch(newVal) {
      if (newVal === 'select') {
        this.getGoetelAreas();
      }
    },
    'project.county'(newVal) {
      let state;
      if (newVal) {
        const returnObj = this.counties.filter(obj => {
          return obj.name === newVal;
        });
        console.log('returnObj', returnObj);
        state = returnObj[0].federalState;
      } else {
        state = undefined;
      }
      this.project.federalState = state;
    }
  },
  async mounted() {
    // this.contactPersons = await this.getCollection('people');

    // this.contactPersons = await this.getCollection('people');
    // this.constructionManagers = await this.getCollection('people');
    // this.distributionAreas = await this.getCollection('distribution-areas');
    this.projectStatuses = await this.getCollection('projectStatuses');
    this.distributionStatuses = await this.getCollection('distributionStatuses');
    this.counties = await this.getCollection('counties');
    this.tariffModels = await this.getCollection('tariffModels');
    this.shippingMethods = await this.getCollection('shippingMethods');
    await this.fetchMondayData();
    // this.financialProjects = await this.getCollection('financial-projects');
    // this.localSalesmans = await this.getCollection('people');
    // this.projectAreas = await this.getCollection('project-areas');
    // this.companies = await this.getCollection('companies');
  },
  methods: {
    getCollection(collection) {
      this.loading = true;
      return HTTP
        .get('/past/' + collection)
        .then((response) => {
          this.loading = false;
          return response.data;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    getNewProjectId() {
      HTTP
        .get('/past/geo/new-project-id')
        .then((response) => {
          console.log(response.data);
          return response.data.toString();
        })
        .catch((error) => console.log(error));
    },
    getGoetelAreas() {
      this.loading = true;
      HTTP
        .get('/past/geo/goetel-area-polygons')
        .then((response) => {
          this.goetelAreas = response.data;
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.loading = false;
        });
    },
    saveProject() {
      let newProjectObject;
      let newProjectId;
      this.loading = true;
      HTTP
        .get('/past/geo/new-project-id')
        .then((response) => {
          console.log(response.data);
          newProjectId = response.data;

          newProjectObject = {
            name: 'TEST-' + this.project.name,
            city: this.project.city,
            district: this.project.district,
            county: this.project.county,
            ktr: parseInt(this.project.ktr),
            // projectStatus: this.project.projectStatusId,
            projectStatus: this.project.projectStatusId ? {
              id: this.project.projectStatusId
            } : null,
            distributionStatus: this.project.distributionStatusId ? {
              id: this.project.distributionStatusId
            } : null,
            tariffModel: this.project.tariffModelId ? {
              id: this.project.tariffModelId
            } : null,
            shippingMethod: this.project.shippingMethodId ? {
              id: this.project.shippingMethodId
            } : null,
            projektId: newProjectId,
            dispatchOnt: this.project.dispatchOnt
          };

          HTTP
            .post('/past/projects', newProjectObject)
            .then((response) => {
              console.log(response.data);
              if (this.multiPolygon && this.multiPolygon.coordinates && this.multiPolygon.coordinates.length) {
                this.saveProjectPolygon(newProjectId);
              }
              this.$store.commit(
                'addNotification',
                new NotificationObject('success', 'Projekt gespeichert')
              );
            })
            .catch((error) => {
              console.log(error);
            });

          this.mondaySubmit(newProjectId);
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.loading = false;
        });
    },
    async fetchMondayData() {
      this.loading = true;

      const { data } = await mondayApi(fetchData, {
        boardIds: [this.monday.board.id]
      });
      this.monday.name = data.account.name;
      this.monday.slug = data.account.slug;
      this.monday.board.name = data.boards[0].name;
      this.monday.board.groups = [];

      data.boards[0].groups.forEach((item) => {
        if (this.monday.board.groups.length === 0) {
          this.monday.board.selectedGroup = item.id;
        }
        this.monday.board.groups.push(item);
      });

      data.boards[0].columns.forEach((item) => {
        this.monday.board.columns.push(item);
      });

      this.loading = false;
    },
    async insertMondayItem(itemName, boardId, groupId, columnValues) {
      const { data } = await mondayApi(insertItem, {
        itemName,
        boardId,
        groupId,
        columnValues: JSON.stringify(columnValues)
      });

      this.monday.entrieUrl = `https://${this.monday.slug}.monday.com/boards/${data.create_item.board.id}/pulses/${data.create_item.id}`;
      this.monday.alert = true;
    },
    mondaySubmit(pId) {
      const columnValues = {};
      if (this.monday.board.projectIdId) {
        columnValues[this.monday.board.projectIdId] = pId;
      }

      this.insertMondayItem(
        this.project.name,
        this.monday.board.id,
        this.monday.board.selectedGroup,
        columnValues
      );
    },
    // Validation Rules
    requiredRule: (v) => {
      return !!v || 'Bitte füllen Sie dieses Feld aus';
    },
    updateCity(city) {
      this.project.city = city.city;
    },
    updateDistrict(district) {
      this.project.district = district;
    },
    updatePolygon(polygon) {
      this.newPolygon = polygon;
    },
    saveProjectPolygon(id) {
      let polygonObject = {};

      polygonObject = {
        projektId: id,
        polygon: this.multiPolygon
      };

      if (this.multiPolygon && this.multiPolygon.coordinates && this.multiPolygon.coordinates.length) {
        HTTP
          .post('/past/geo/save-project-polygon', polygonObject, {
            headers: this.headers
          })
          .then((response) => {
            console.log(response.data);
            // alert('Polygon gespeichert');
            this.$store.commit(
              'addNotification',
              new NotificationObject('success', 'Projekt-Polygon gespeichert')
            );
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        alert('Keine korrekten Geometriedaten zum Speichern vorhanden!')
      }
    },
    // validation rules
    numericRule: v => {
      if (!v) return 'Bitte geben Sie eine Nummer ein';
      if (!(v && /\d+/i).test(v)) return 'Bitte geben Sie eine Nummer ein';
      return true;
    },
  }
};
</script>
<style lang="scss" scoped>
form h4 {
  font-size: 20px;
  color: #209bd8;
  border-bottom: 1px solid #ddd;
  margin: 10px 0 20px;
}
.v-card {
  margin-bottom: 15px;
}
</style>

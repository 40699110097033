<template>
  <div class="login">
    <div>
      <form class="login-form" :onsubmit="loginuser">
        <div>
          <img width="80" :src="`${publicPath}logo.svg`" />
          <spot-logo :showSubtitle="true" :login="true" size="l" style="margin-top: -10px;" />
        </div>
        <div>
          <v-text-field
            ref="username"
            v-model="username"
            label="Benutzername"
            flat
            autofocus
            :rules="[requiredUsernameRule]"
            @keydown.enter="loginuser"
          />
          <v-text-field
            ref="password"
            v-model="password"
            label="Passwort"
            flat
            type="password"
            :rules="[requiredPasswordRule]"
            @keydown.enter="loginuser"
          />
          <v-btn
            color="primary"
            elevation="2"
            class="float-right"
            :disabled="!username || !password"
            @click="loginuser"
          >
            Anmelden
          </v-btn>
        </div>
        <TwoFactorAuthenticationPopup
          ref="tfaPopup"
          :authResponse="authResponse"
        />
      </form>
    </div>
  </div>
</template>

<script>
import Authentication from '@/main/Authentication.js';
import NotificationObject from '@/main/NotificationObject.js';
import spotLogo from '@/main/elements/spotLogo.vue';
import TwoFactorAuthenticationPopup from '@/main/elements/TwoFactorAuthenticationPopup.vue';

export default {
  name: 'Login',
  components: { spotLogo, TwoFactorAuthenticationPopup },
  data: () => ({
    username: undefined,
    password: undefined,
    isLoading: false,
    errors: [],
    visitedInputFields: [],
    publicPath: process.env.BASE_URL,
    authResponse: {},
    pageFrom: null
  }),
  mounted() {
    const redirect = this.$auth.redirect();
    this.pageFrom = redirect
      ? redirect.from
        ? redirect.from.fullPath
        : null
      : null;
    document.title = `Login  | ${process.env.VUE_APP_TITLE ?? 'Goetel SPOT'}`;
  },
  methods: {
    requiredUsernameRule: (v) => {
      return !!v || 'Benutzername benötigt';
    },
    requiredPasswordRule: (v) => {
      return !!v || 'Passwort benötigt';
    },
    loginuser() {
      if (
        !this.$refs.username.validate() ||
        !this.$refs.password.validate()
      ) {
        return false;
      }
      this.isLoading = true;
      Authentication.login(this.username, this.password)
        .then((resp) => {
          if (resp.twoFactorAuthExempt === true) {
            this.$auth.user({
              userName: resp.userName,
              userRoles: resp.userRoles,
              twoFactorAuthenticated: true
            });
            this.$auth.token('token', resp.token, true);
            this.$store.commit('setUsername', resp.userName);
            this.$store.commit('loginChange', 'SUCCESS');
            if (this.pageFrom) this.$router.push(this.pageFrom);
          } else {
            // Display two factor auth popup
            this.authResponse = resp;
            this.$refs.tfaPopup.show();
          }
        })
        .catch(() => this.loginErrorHandler())
        .finally(() => {
          this.isLoading = false;
        });
    },
    loginErrorHandler: function () {
      this.password = '';
      this.$store.commit('loginChange', 'FAILED');
      this.$store.commit(
        'addNotification',
        new NotificationObject('error', 'Die Anmeldung war nicht erfolgreich!')
      );
    }
  }
};
</script>

<style scoped>
.login-form {
  margin-top: 30vh;
}
.login {
  width: max-content;
  height: 100%;
  margin: auto;
  position: relative;
}

.login .box {
  height: max-content;
  position: absolute;
  top: 50%;
  transform: translate(-50%, calc(-50% - var(--goe-spacing-8)));
  width: max-content;
  max-width: 100vw;
  min-width: 600px;
  position: absolute;
  margin: auto;
}

form {
  background: var(--goe-background-light);
}

.content {
  display: grid;
  grid-template-columns: max-content auto;
  padding: var(--goe-spacing-0);
}

.content > div {
  padding: var(--goe-spacing-4);
}

.content > div:nth-child(1) {
  background: var(--goe-background-clear);
  user-select: none;
  z-index: -1;
  display: grid;
}

.logo-wrapper {
  display: grid;
  grid-template-rows: max-content max-content;
  margin: auto;
  padding-bottom: var(--goe-spacing-2);
}

.logo-wrapper img {
  width: var(--goe-spacing-12);
}

.logo-wrapper > span {
  text-transform: lowercase;
  color: var(--goe-fontColor-highlightDark);
  font-size: var(--goe-fontSize-normal);
  margin: auto;
}
</style>

import { DateTimeFormatter, LocalDateTime } from '@js-joda/core';

function formatLocalDate(date) {
  return new Date(date).toLocaleDateString('de-DE', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  });
}

const dateFormatter = DateTimeFormatter.ofPattern("yyyy-MM-dd'T'HH:mm:ss.SSSZ");

function formatDateTimeWithoutUhr(date) {
  return LocalDateTime.parse(date, dateFormatter).format(
    DateTimeFormatter.ofPattern('dd.MM.yyy HH:mm')
  );
}

function formatDateTime(date) {
  return LocalDateTime.parse(date, dateFormatter).format(
    DateTimeFormatter.ofPattern("dd.MM.yyy HH:mm 'Uhr'")
  );
}

function formatDateTimeNoMillies(date) {
  return LocalDateTime.parse(
    date,
    DateTimeFormatter.ofPattern("yyyy-MM-dd'T'HH:mm:ss'Z'")
  ).format(DateTimeFormatter.ofPattern("dd.MM.yyy HH:mm 'Uhr'"));
}

function formatTicketDateTime(date) {
  return LocalDateTime.parse(
    date,
    DateTimeFormatter.ofPattern('yyyy-MM-dd HH:mm:ss')
  ).format(DateTimeFormatter.ofPattern('dd.MM.yyyy HH:mm'));
}

function formatDate(date) {
  return LocalDateTime.parse(date, dateFormatter).format(
    DateTimeFormatter.ofPattern('dd.MM.yyy')
  );
}

function formatRetoureDateTime(date) {
  const retoureDateFormatter = DateTimeFormatter.ofPattern("yyyy-MM-dd'T'HH:mm");
  return LocalDateTime.parse(date, retoureDateFormatter).format(
    DateTimeFormatter.ofPattern("dd.MM.yyy HH:mm 'Uhr'")
  );
}

const intlFormatter = Intl.DateTimeFormat('de-DE', {
  timeZone: 'Europe/Berlin',
  day: 'numeric',
  month: 'long',
  year: 'numeric',
  weekday: 'long',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric'
});

function parseIntl(date) {
  if (typeof date === 'string') return new Date(date);
  if (typeof date === 'number') return new Date(date);
  return date;
}

function intlFormatDateTime(date, customIntlFormatter = undefined) {
  if (!customIntlFormatter) return intlFormatter.format(parseIntl(date));
  else return customIntlFormatter.format(parseIntl(date));
}

export default {
  formatLocalDate,
  dateFormatter,
  formatDateTime,
  formatDateTimeNoMillies,
  formatDate,
  formatTicketDateTime,
  formatDateTimeWithoutUhr,
  formatRetoureDateTime,
  intlFormatDateTime
};

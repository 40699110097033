<template>
  <div>
    <v-card>
      <v-tabs background-color="rgba(0,0,0,0.05)">
        <v-tab
          v-if="false"
          @change="
            () => {
              cityDistrictTabActive = true;
              projectTabActive = false;
            }
          "
        >
          <v-icon left>
            mdi-home-city-outline
          </v-icon>
          Ort / Ortsteil
        </v-tab>
        <v-tab
          @change="
            () => {
              cityDistrictTabActive = false;
              projectTabActive = true;
            }
          "
        >
          <v-icon left>
            mdi-vector-polygon
          </v-icon>
          Projekt
        </v-tab>
        <v-tab-item v-if="cityDistrictTabActive">
          <v-card-text>
            <AddressDistrictSelection
              :city="city"
              :district="district"
              @updateCity="updateCity"
              @updateDistrict="updateDistrict"
              @updateDistrictObject="updateDistrictObject"
            />
          </v-card-text>
        </v-tab-item>
        <v-tab-item>
          <v-card-text>
            <ProjectNameSelection
              :projectItem="projectItem"
              @updateProjektItem="updateProjektItem"
            />
          </v-card-text>
        </v-tab-item>
      </v-tabs>
      <v-card-actions>
        <template
          v-if="projectItem && projectItem.constructionManagementTool === 'TKI'"
        >
          <v-alert
            dense
            outlined
            type="error"
          >
            <b>Dieses Projekt wird ausschließlich mit TKI geplant.
              Excel-Kundenbauliste ist nicht verfügbar.</b>
          </v-alert>
        </template>
        <template
          v-else-if="
            projectItem &&
              projectItem.constructionManagementTool === 'Online_Kundenbauliste'
          "
        >
          <v-alert
            dense
            outlined
            type="error"
          >
            <b>Dieses Projekt wird ausschließlich mit der
              <a href="/online-construction-list">Online-Kundebauliste</a>
              geplant. Excel-Kundenbauliste ist nicht verfügbar.</b>
          </v-alert>
        </template>
        <template v-else>
          <v-btn
            v-if="cityDistrictTabActive"
            class="margin-right-1"
            color="primary"
            :disabled="!district"
            @click="getHouseconnectionContractsByCityCodeAndDistrict"
          >
            <v-icon>mdi-chart-box-plus-outline</v-icon>
            Bauliste anzeigen
          </v-btn>
          <v-btn
            v-if="cityDistrictTabActive"
            color="primary"
            :disabled="!tableData || tableData.length === 0"
            @click="exportGncConstructionList(city, district)"
          >
            Export
          </v-btn>
          <v-btn
            v-if="projectTabActive"
            class="margin-right-1"
            color="primary"
            :disabled="!projectItem"
            @click="getProjectData"
          >
            <v-icon>mdi-chart-box-plus-outline</v-icon>
            Bauliste anzeigen
          </v-btn>
          <v-btn
            v-if="projectTabActive"
            color="primary"
            :disabled="!projectTableData || projectTableData.length === 0"
            @click="exportProjectData"
          >
            Export
          </v-btn>
          <v-switch
            v-if="projectTabActive"
            v-model="exportProjectAsync"
            color="primary"
            class="ml-4"
            :disabled="!projectTableData || projectTableData.length === 0"
            :label="`Asynchron: ${exportProjectAsync ? 'Ja' : 'Nein'}`"
          ></v-switch>
          <span
            class="ml-2"
            style="font-size: 10px; color: rgba(0, 0, 0, 0.6)"
          >(Bei großen Projekten mit über 500 Adressen bitte asynchron
            auswählen)</span>
        </template>
      </v-card-actions>
    </v-card>
    <!-- City/District tab view -->
    <template v-if="cityDistrictTabActive">
      <v-data-table
        v-model="selected"
        :headers="tableHeaders"
        :items="tableData"
        item-key="contractId"
        class="elevation-1"
        :single-expand="false"
        show-expand
        :expanded.sync="expandedRows"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :loading="dataLoading"
        loading-text="Daten werden geladen..."
        no-data-text="Es sind keine Einträge vorhanden."
        no-results-text="Für den angegebenen Ort konnten keine Einträge gefunden werden."
        :footer-props="{
          showFirstLastPage: true,
          showCurrentPage: true,
          'items-per-page-text': 'Zeilen pro Seite:',
          'items-per-page-options': [10, 25, 50, 100, -1]
        }"
        :items-per-page.sync="rowsPerPageDefault"
        @item-expanded="
          if ($event.value === true) {
            loadServiceOrdersAtInstallationAddress($event.item);
          }
        "
      >
        <template #top>
          <v-toolbar flat>
            <v-toolbar-title>
              Übersicht der Bauliste eines Ortsteils
            </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>

        <template #item="row">
          <tr
            :class="
              row.isExpanded
                ? 'v-data-table__expanded v-data-table__expanded__row'
                : ''
            "
            @click="row.expand(!row.isExpanded)"
          >
            <td></td>
            <td v-if="row.item.hasDuplicate">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    style="color: red"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-home-city
                  </v-icon>
                </template>
                <span>Zwei Installationsadressen im Vertrag</span>
              </v-tooltip>
            </td>
            <td v-else>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">
                    mdi-home-city
                  </v-icon>
                </template>
                <span>Hausanschluss</span>
              </v-tooltip>
            </td>
            <td>{{ row.item.contractId }}</td>
            <td>{{ row.item.exportDate }}</td>
            <td>{{ row.item.exportUser }}</td>
            <td>{{ row.item.address.zipCode }}</td>
            <td>{{ row.item.address.city }}</td>
            <td>{{ row.item.address.district }}</td>
            <td>{{ row.item.address.street }}</td>
            <td>
              {{ row.item.address.houseNumber }}
              {{ row.item.address.houseNumberSupplement }}
            </td>
            <td>{{ row.item.customerId }}</td>
            <td>{{ row.item.address.firstName }}</td>
            <td>{{ row.item.address.lastName }}</td>
            <td>{{ row.item.address.phone }}</td>
            <td>{{ row.item.address.cellPhoneNumber }}</td>
            <td>{{ row.item.address.email }}</td>
          </tr>
        </template>
        <template v-slot:expanded-item="{ item }">
          <tr
            v-for="(order, index) in item.serviceOrders"
            :key="order.contractId"
            :class="
              item.serviceOrders.length === 1
                ? 'spot-box-shadow'
                : index === 0
                  ? 'spot-box-shadow-top'
                  : index === item.serviceOrders.length - 1
                    ? 'spot-box-shadow-bottom'
                    : ''
            "
          >
            <td></td>
            <td v-if="order.contractId !== undefined">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">
                    mdi-web
                  </v-icon>
                </template>
                <span>Diensteauftrag</span>
              </v-tooltip>
            </td>
            <td>
              {{ order.contractId }}
            </td>
            <td>
              {{ order.exportDate }}
            </td>
            <td>
              {{ order.exportUser }}
            </td>
            <td>
              {{ order.address.zipCode }}
            </td>
            <td>
              {{ order.address.city }}
            </td>
            <td>
              {{ order.address.district }}
            </td>
            <td>
              {{ order.address.street }}
            </td>
            <td>
              {{ order.address.houseNumber }}
              {{ order.address.houseNumberSupplement }}
            </td>
            <td>
              {{ order.customerId }}
            </td>
            <td>
              {{ order.address.firstName }}
            </td>
            <td>
              {{ order.address.lastName }}
            </td>
            <td>
              {{ order.address.phone }}
            </td>
            <td>
              {{ order.address.cellPhoneNumber }}
            </td>
            <td>
              {{ order.address.email }}
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-overlay v-if="loadingExport">
        <v-progress-circular
          :size="120"
          color="primary"
          indeterminate
        >
        </v-progress-circular>
      </v-overlay>
    </template>
    <!-- Project details -->
    <template v-if="projectTabActive">
      <v-data-table
        v-model="selected"
        :headers="projectTableHeaders"
        :items="projectTableData"
        item-key="contractId"
        class="elevation-1"
        :single-expand="false"
        show-expand
        :expanded.sync="expandedRows"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :loading="dataLoading"
        loading-text="Daten werden geladen..."
        no-data-text="Es sind keine Einträge vorhanden."
        no-results-text="Für den angegebenen Ort konnten keine Einträge gefunden werden."
        :footer-props="{
          showFirstLastPage: true,
          showCurrentPage: true,
          'items-per-page-text': 'Zeilen pro Seite:',
          'items-per-page-options': [10, 25, 50, 100, -1]
        }"
        :items-per-page.sync="rowsPerPageDefault"
        @item-expanded="
          if ($event.value === true) {
            loadServiceOrdersAtInstallationAddressForProject($event.item);
          }
        "
      >
        <template #top>
          <v-toolbar flat>
            <v-toolbar-title>
              Übersicht der Bauliste eines Ortsteils
            </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>

        <template #item="row">
          <tr
            :class="
              row.isExpanded
                ? 'v-data-table__expanded v-data-table__expanded__row'
                : ''
            "
            @click="row.expand(!row.isExpanded)"
          >
            <td></td>
            <td v-if="row.item.hasDuplicate">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    style="color: red"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-home-city
                  </v-icon>
                </template>
                <span>Zwei Installationsadressen im Vertrag</span>
              </v-tooltip>
            </td>
            <td v-else>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">
                    mdi-home-city
                  </v-icon>
                </template>
                <span>Hausanschluss</span>
              </v-tooltip>
            </td>
            <td>{{ row.item.contractId }}</td>
            <td>{{ row.item.exportDate }}</td>
            <td>{{ row.item.exportUser }}</td>
            <td>{{ row.item.address.zipCode }}</td>
            <td>{{ row.item.address.city }}</td>
            <td>{{ row.item.address.district }}</td>
            <td>{{ row.item.address.street }}</td>
            <td>
              {{ row.item.address.houseNumber }}
              {{ row.item.address.houseNumberSupplement }}
            </td>
            <td>{{ row.item.customerId }}</td>
            <td>{{ row.item.address.firstName }}</td>
            <td>{{ row.item.address.lastName }}</td>
            <td>{{ row.item.address.phone }}</td>
            <td>{{ row.item.address.cellPhoneNumber }}</td>
            <td>{{ row.item.address.email }}</td>
          </tr>
        </template>
        <template v-slot:expanded-item="{ item }">
          <tr
            v-for="(order, index) in item.serviceOrders"
            :key="order.contractId"
            :class="
              item.serviceOrders.length === 1
                ? 'spot-box-shadow'
                : index === 0
                  ? 'spot-box-shadow-top'
                  : index === item.serviceOrders.length - 1
                    ? 'spot-box-shadow-bottom'
                    : ''
            "
          >
            <td></td>
            <td v-if="order.contractId !== undefined">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">
                    mdi-web
                  </v-icon>
                </template>
                <span>Diensteauftrag</span>
              </v-tooltip>
            </td>
            <td>
              {{ order.contractId }}
            </td>
            <td>
              {{ order.exportDate }}
            </td>
            <td>
              {{ order.exportUser }}
            </td>
            <td>
              {{ order.address.zipCode }}
            </td>
            <td>
              {{ order.address.city }}
            </td>
            <td>
              {{ order.address.district }}
            </td>
            <td>
              {{ order.address.street }}
            </td>
            <td>
              {{ order.address.houseNumber }}
              {{ order.address.houseNumberSupplement }}
            </td>
            <td>
              {{ order.customerId }}
            </td>
            <td>
              {{ order.address.firstName }}
            </td>
            <td>
              {{ order.address.lastName }}
            </td>
            <td>
              {{ order.address.phone }}
            </td>
            <td>
              {{ order.address.cellPhoneNumber }}
            </td>
            <td>
              {{ order.address.email }}
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-overlay v-if="loadingExport">
        <v-progress-circular
          :size="120"
          color="primary"
          indeterminate
        >
        </v-progress-circular>
      </v-overlay>
    </template>
    <v-dialog v-model="asyncReportStarted" max-width="720">
      <v-card>
        <v-card-title class="text-h5">
          Asynchroner Report gestartet
        </v-card-title>
        <v-card-text>
          <p>Der Asynchrone Report wurde gestartet.</p>
          <p>
            Das Ergebnis kann in der
            <router-link to="/">
              Aufgabenliste (GNC Export steht bereit)
            </router-link>
            abgerufen werden, sobald die Datei erzeugt wurde.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-alert dense type="info">
            Das erzeugen der Datei kann mehrere Minuten dauern.
          </v-alert>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="asyncReportStarted = false"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PortingProcessDashboard from '@/dashboard/PortingProcessDashboard.vue';
import NotificationObject from '@/main/NotificationObject.js';
import ErrorMessageBuilder from '@/util/ErrorMessageBuilder.js';
import { HTTP } from '@/main/httpClient.js';
import loader from '@/components/Loader.vue';
export default {
  name: 'OrderOverview',
  components: {
    PortingProcessDashboard,
    NotificationObject,
    HTTP,
    loader,
    AddressDistrictSelection: () =>
      import('@/components/statistic/AddressDistrictSelection.vue'),
    ProjectNameSelection: () =>
      import('@/components/statistic/ProjectNameSelection.vue')
  },
  data: () => ({
    loadingExport: false,
    rowsPerPageDefault: 50,
    expanded: [],
    selectedItem: null,
    dialog: false,
    dataLoading: false,
    tableData: [],
    projectTableData: [],
    selected: [],
    sortBy: 'dateProcessStart',
    sortDesc: true,
    addresses: undefined,
    expandedRows: [],
    toggles: [],
    city: null,
    district: '',
    districtItems: [],
    availableCities: [],
    isUpdating: false,
    search: '',
    loadingCities: false,
    loadingDistricts: false,
    districtObject: undefined,
    projectTabActive: true,
    cityDistrictTabActive: false,
    projectItem: undefined,
    exportProjectAsync: false,
    asyncReportStarted: false,
    tableHeaders: [
      { text: 'Typ' },
      {
        text: 'Vertrags ID',
        align: 'start',
        sortable: true,
        value: 'contractId',
        class: 'title-attr'
      },
      { text: 'Export Datum', value: 'exportDate' },
      { text: 'Export User', value: 'exportUser' },
      { text: 'PLZ', value: 'address.zipCode' },
      { text: 'Ort', value: 'address.city' },
      { text: 'Ortsteil', value: 'address.district' },
      { text: 'Straße', value: 'address.street' },
      { text: 'Hausnummer', value: 'address.houseNumber' },
      { text: 'Kundennummer', value: 'customerId' },
      { text: 'Vorname', value: 'address.firstName' },
      { text: 'Nachname', value: 'address.lastName' },
      { text: 'Festnetz', value: 'address.phone' },
      { text: 'Mobil', value: 'address.cellPhoneNumber' },
      { text: 'E-Mail', value: 'address.email' }
    ],
    projectTableHeaders: [
      { text: 'Typ' },
      {
        text: 'Vertrags ID',
        align: 'start',
        sortable: true,
        value: 'contractId',
        class: 'title-attr'
      },
      { text: 'Export Datum', value: 'exportDate' },
      { text: 'Export User', value: 'exportUser' },
      { text: 'PLZ', value: 'address.zipCode' },
      { text: 'Ort', value: 'address.city' },
      { text: 'Ortsteil', value: 'address.district' },
      { text: 'Straße', value: 'address.street' },
      { text: 'Hausnummer', value: 'address.houseNumber' },
      { text: 'Kundennummer', value: 'customerId' },
      { text: 'Vorname', value: 'address.firstName' },
      { text: 'Nachname', value: 'address.lastName' },
      { text: 'Festnetz', value: 'address.phone' },
      { text: 'Mobil', value: 'address.cellPhoneNumber' },
      { text: 'E-Mail', value: 'address.email' }
    ]
  }),
  methods: {
    showMessage: function (type, description) {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, description)
      );
    },
    row_classes(item) {
      if (item.match === 1) {
        return 'highlighted-row';
      }
    },
    exportGncConstructionList: function () {
      this.loadingExport = true;
      let zip;
      let cityString;
      let districtString;
      if (
        this.districtObject &&
        this.districtObject.zip &&
        this.districtObject.city &&
        this.districtObject.district
      ) {
        zip = this.districtObject.zip;
        cityString = this.districtObject.city;
        districtString = this.districtObject.district;
      } else {
        console.log('Wrong condition true');
        zip = this.city.zip;
        cityString = this.city.city;
        districtString = this.district ? this.district : '';
      }

      HTTP.get(
        `/gncReport/exportGncConstructionList?zip=${zip}&city=${cityString}&district=${districtString}`,
        { responseType: 'blob' }
      )
        .then((resp) => {
          const responseHeaders = resp.headers;
          const mediaType = responseHeaders['content-type'];
          const fileName = responseHeaders['content-disposition'].split('=')[1];
          const blob = new Blob([resp.data], {
            type: mediaType
          });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Die Daten konnten nicht exportiert werden'
            )
          )
        )
        .finally(() => {
          this.loadingExport = false;
        });
    },
    exportProjectData() {
      this.loadingExport = true;

      if (this.exportProjectAsync) {
        return this.exportProjectAsyncStart();
      }

      HTTP.get(
        `/gncReport/exportGncConstructionList?projectId=${this.projectItem.projektId}`,
        {
          responseType: 'blob'
        }
      )
        .then((resp) => {
          const responseHeaders = resp.headers;
          const mediaType = responseHeaders['content-type'];
          const fileName = responseHeaders['content-disposition'].split('=')[1];
          const blob = new Blob([resp.data], {
            type: mediaType
          });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Die Daten konnten nicht exportiert werden'
            )
          )
        )
        .finally(() => {
          this.loadingExport = false;
        });
    },
    exportProjectAsyncStart: function () {
      HTTP.post(
        `/gncReport/requestAsyncGncReport?projectId=${this.projectItem.projektId}`
      )
        .then((resp) => {
          this.asyncReportStarted = true;
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Die Daten konnten nicht exportiert werden'
            )
          )
        )
        .finally(() => {
          this.loadingExport = false;
        });
    },
    loadServiceOrdersAtInstallationAddress: function (item) {
      HTTP.get('/gncReport/serviceOrderContracts/byInstallationAddress', {
        params: {
          zip: item.address.zipCode,
          street: item.address.street,
          houseNo: item.address.houseNumber,
          houseNoSup: item.address.houseNumberSupplement
        }
      })
        .then((resp) => {
          if (resp.data !== null) {
            item.serviceOrders = resp.data;
          }
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Die Daten konnten nicht geladen werden'
            )
          )
        )
        .finally(() => {});
    },
    loadServiceOrdersAtInstallationAddressForProject: function (item) {
      HTTP.get('/gncReport/serviceOrderContracts/byProject', {
        params: {
          zip: item.address.zipCode,
          street: item.address.street,
          houseNo: item.address.houseNumber,
          houseNoSup: item.address.houseNumberSupplement,
          projectId: this.projectItem.projektId
        }
      })
        .then((resp) => {
          if (resp.data !== null) {
            item.serviceOrders = resp.data;
          }
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Die Daten konnten nicht geladen werden'
            )
          )
        )
        .finally(() => {});
    },
    getHouseconnectionContractsByCityCodeAndDistrict: function () {
      if (
        (this.city && this.city.city && this.city.zip) ||
        (this.districtObject &&
          this.districtObject.zip &&
          this.districtObject.city &&
          this.districtObject.district)
      ) {
        this.loading = true;
        let zip;
        let citystring;
        let districtString;
        if (
          this.districtObject &&
          this.districtObject.zip &&
          this.districtObject.city &&
          this.districtObject.district
        ) {
          zip = this.districtObject.zip;
          citystring = this.districtObject.city;
          districtString = this.districtObject.district;
        } else if (this.city && this.city.zip && this.city.city) {
          zip = this.city.zip;
          citystring = this.city.city;
          districtString = this.district ? this.district : '';
        }
        this.dataLoading = true;
        HTTP.get(
          `/gncReport/addressesWithHouseconnectionOrder?zip=${zip}&city=${citystring}&district=${districtString}`
        )
          .then((resp) => {
            this.tableData = resp.data.map((item) => {
              return {
                serviceOrders: [],
                contractTypeIcon: 'mdi-web',
                ...item
              };
            });
          })
          .catch((err) =>
            this.showMessage(
              'error',
              ErrorMessageBuilder.buildErrorMessage(
                err.response.data,
                'Die Daten konnten nicht geladen werden'
              )
            )
          )
          .finally(() => {
            this.dataLoading = false;
          });
      }
    },
    updateCity(city) {
      this.city = city;
    },
    updateDistrict(district) {
      this.district = district;
      if (!district) {
        this.tableData = [];
      }
    },
    updateDistrictObject(obj) {
      this.districtObject = obj;
      if (!obj) {
        this.tableData = [];
      }
    },
    updateProjektItem(projectItem) {
      if (this.projectTabActive) {
        this.projectItem = projectItem;
        if (!projectItem) {
          this.projectTableData = [];
        }
      }
    },
    getProjectData() {
      this.dataLoading = true;
      HTTP.get(
        `/gncReport/addressesInProject?projectId=${this.projectItem.projektId}`
      )
        .then((resp) => {
          this.projectTableData = resp.data.map((item) => {
            return {
              serviceOrders: [],
              contractTypeIcon: 'mdi-web',
              ...item
            };
          });
          this.exportProjectAsync = this.projectTableData.length > 500;
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Die Daten konnten nicht geladen werden'
            )
          )
        )
        .finally(() => {
          this.dataLoading = false;
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.red {
  box-shadow: 0 0 0 100vmax rgba(0, 0, 0, 0.6);
}
.spot-box-shadow-bottom {
  box-shadow: inset 0px -4px 8px -5px rgba(50, 50, 50, 0.75);
}

.spot-box-shadow-top {
  box-shadow: inset 0px 4px 8px -5px rgba(50, 50, 50, 0.75);
}
.spot-box-shadow {
  box-shadow: inset 0px -4px 8px -5px rgba(50, 50, 50, 0.75),
    inset 0px 4px 8px -5px rgba(50, 50, 50, 0.75);
}
</style>

const getTrackingUrl = (logisticCompany, trackingId) => {
  if (!logisticCompany || !trackingId) return;
  switch (logisticCompany.toUpperCase()) {
    case 'DHL': {
      return `https://www.dhl.de/de/privatkunden/pakete-empfangen/verfolgen.html?piececode=${trackingId}`;
    }
    case 'GLS': {
      return `https://www.gls-pakete.de/sendungsverfolgung?trackingNumber=${trackingId}`;
    }
    default: {
      return `https://www.google.de?q=${logisticCompany}%20tracking%20${trackingId}`;
    }
  }
}

export {
  getTrackingUrl
}

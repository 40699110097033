/**
 * Flatten a multidimensional object
 *
 * @param { a: 1, b: { c: 2 } }
 * @returns { a: 1, c: 2}
 */
function flattenObject(obj) {
  const flattened = {};

  Object.keys(obj).forEach((key) => {
    const value = obj[key];

    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      Object.assign(flattened, flattenObject(value));
    } else {
      flattened[key] = value;
    }
  });

  return flattened;
}

/**
 * Remove null, undefined and empty properties from the object.
 *
 * @param { customerId:null, contractId:123345, firstName:"" }
 * @returns { contractId:123345 }
 */
function removeEmptyProperties(obj) {
  const validObj = flattenObject(obj);

  Object.keys(validObj).forEach((key) => {
    if (
      validObj[key] === null ||
      validObj[key] === undefined ||
      validObj[key].length === 0
    ) {
      delete validObj[key];
    }
  });
  return validObj;
}

/**
 * Convert object to QueryString.
 *
 * @param { customerId:444332, contractId:123345, firstName:"" }
 * @returns customerId=444332&contractId=123345
 */
function objectToQueryParamString(obj) {
  const flatObj = removeEmptyProperties(obj);
  return Object.keys(flatObj)
    .map((key) => {
      return key + '=' + flatObj[key];
    })
    .join('&');
}

/**
 * Convert object to QueryString.
 *
 * @param { customerId:444332, contractId:123345, firstName:"" }
 * @returns customerId=444332&contractId=123345
 */
function objectToEncodedQueryParamString(obj) {
  const flatObj = removeEmptyProperties(obj);
  return Object.keys(flatObj)
    .map((key) => {
      return key + '=' + encodeURIComponent(flatObj[key]);
    })
    .join('&');
}

export { flattenObject, removeEmptyProperties, objectToQueryParamString, objectToEncodedQueryParamString };

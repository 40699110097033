<template>
  <div>
    <v-row no-gutters>
      <v-col md="8">
        <vl-map
          style="height: calc(100vh - 155px)"
          :load-tiles-while-animating="true"
          :load-tiles-while-interacting="false"
        >
          <vl-view :zoom.sync="zoom" :center.sync="center"></vl-view>

          <!-- <vl-layer-vector :z-index="1">
            <vl-source-vector
              :features.sync="features"
              ident="the-source"
            ></vl-source-vector>

            <vl-style-box>
              <vl-style-stroke color="green"></vl-style-stroke>
              <vl-style-fill color="rgba(255,255,255,0.5)"></vl-style-fill>
            </vl-style-box>
          </vl-layer-vector> -->
          <!-- <vl-feature>
            <vl-overlay id="overlay" position="[30, 30]">
              <template slot-scope="scope">
                <div class="overlay-content">
                  Hello world!<br />
                  Position: {{ scope.position }}
                </div>
              </template>
            </vl-overlay>
            <vl-style-box>
              <vl-style-stroke color="red"></vl-style-stroke>
              <vl-style-fill color="rgba(255,0,0,0.5)"></vl-style-fill>
            </vl-style-box>
          </vl-feature> -->
          <!-- <vl-interaction-draw
            type="Polygon"
            source="the-source"
            @stop-click="alert(this.point)"
          >
            <vl-style-box>
              <vl-style-stroke color="blue"></vl-style-stroke>
              <vl-style-fill color="rgba(255,255,255,0.5)"></vl-style-fill>
            </vl-style-box>
          </vl-interaction-draw> -->

          <vl-interaction-select
            type="Polygon"
            source="the-source"
            :features.sync="selectedFeatures"
          >
            <vl-style-box>
              <vl-style-stroke color="red"></vl-style-stroke>
              <vl-style-fill color="rgba(255,0,0,0.5)"></vl-style-fill>
            </vl-style-box>
          </vl-interaction-select>

          <vl-layer-tile :z-index="0">
            <vl-source-osm></vl-source-osm>
          </vl-layer-tile>
          <vl-feature> </vl-feature>

          <template v-if="countyArea && !vergabeClusterPolygons">
            <vl-feature
              v-if="countyArea && countyArea[0]"
              :id="countyArea[0].id"
            >
              <vl-geom-multi-polygon
                v-if="countyArea && countyArea[0].st_asgeojson"
                :coordinates="
                  JSON.parse(countyArea[0].st_asgeojson).coordinates
                "
              ></vl-geom-multi-polygon>
              <vl-style-box>
                <vl-style-fill color="rgba(255,255,255,0.55)"></vl-style-fill>
                <vl-style-stroke
                  color="#0000ff"
                  :width="2"
                  :line-dash="[2, 5]"
                ></vl-style-stroke>
              </vl-style-box>
            </vl-feature>

            <vl-feature
              v-if="countyArea && countyArea[1]"
              :id="countyArea[1].id"
            >
              <vl-geom-multi-polygon
                v-if="countyArea && countyArea[1].st_asgeojson"
                :coordinates="
                  JSON.parse(countyArea[1].st_asgeojson).coordinates
                "
              ></vl-geom-multi-polygon>
              <vl-style-box>
                <vl-style-fill color="rgba(255,255,255,0.55)"></vl-style-fill>
                <vl-style-stroke
                  color="#0000ff"
                  :width="4"
                  :line-dash="[2, 5]"
                ></vl-style-stroke>
              </vl-style-box>
            </vl-feature>
          </template>
          <template v-if="vergabeClusterPolygons || vertriebsClusterPolygons">
            <vl-feature v-if="vergabeClusterPolygons">
              <vl-geom-multi-polygon
                v-if="vergabeClusterPolygons"
                :coordinates="JSON.parse(vergabeClusterPolygons).coordinates"
              ></vl-geom-multi-polygon>
              <vl-style-box>
                <vl-style-fill color="rgba(255,255,255,0.55)"></vl-style-fill>
                <vl-style-stroke
                  color="#0000ff"
                  :width="4"
                  :line-dash="[2, 5]"
                ></vl-style-stroke>
              </vl-style-box>
            </vl-feature>

            <template v-for="(item, index2) in vertriebsClusterPolygons">
              <vl-feature v-if="item && item.st_asgeojson" :key="'v' + index2">
                <vl-geom-multi-polygon
                  :coordinates="JSON.parse(item.st_asgeojson).coordinates"
                ></vl-geom-multi-polygon>
                <vl-style-box>
                  <vl-style-fill color="rgba(255,255,255,0.55)"></vl-style-fill>
                  <vl-style-stroke color="#00aaff" :width="2"></vl-style-stroke>
                  <vl-style-text
                    :text="item.vertriebscluster_naming"
                    font="bold 1rem sans-serif"
                    color="#000000"
                  ></vl-style-text>
                </vl-style-box>
              </vl-feature>
            </template>
          </template>

          <template
            v-if="
              !vergabeClusterPolygons &&
              !vertriebsClusterPolygons &&
              vergabeClusterOverview
            "
          >
            <template v-for="(item, index3) in vergabeClusterOverview">
              <vl-feature
                v-if="item && item.st_asgeojson"
                :key="'v' + index3"
                :id="item.cluster_id"
              >
                <vl-geom-multi-polygon
                  :coordinates="JSON.parse(item.st_asgeojson).coordinates"
                ></vl-geom-multi-polygon>
                <vl-style-box>
                  <vl-style-fill color="rgba(255,255,255,0.55)"></vl-style-fill>
                  <vl-style-stroke
                    color="#0000ff"
                    :width="4"
                    :line-dash="[2, 5]"
                  ></vl-style-stroke>
                  <vl-style-text
                    :text="item.cluster_id.toString()"
                    font="bold 1.5rem sans-serif"
                    color="#0000ff"
                  ></vl-style-text>
                </vl-style-box>
              </vl-feature>
            </template>
          </template>

          <template v-if="projects && mergedProjects && mergedProjects.length">
            <template v-for="(item, index) in mergedProjects">
              <vl-feature
                v-if="
                  item &&
                  item.poly &&
                  item.poly.length &&
                  Array.isArray(item.poly)
                "
                :id="item.projektId"
                :key="index"
              >
                <vl-geom-multi-polygon
                  :coordinates="item.poly"
                ></vl-geom-multi-polygon>
                <vl-style-box>
                  <vl-style-fill :color="item.fillColor"></vl-style-fill>
                  <vl-style-stroke
                    :color="item.borderColor"
                    :width="3"
                  ></vl-style-stroke>
                  <vl-style-text
                    :text="item.projektId.toString()"
                    font="bold 1rem sans-serif"
                    color="#ffffff"
                  ></vl-style-text>
                </vl-style-box>
              </vl-feature>
            </template>
          </template>

          <template
            v-else-if="
              clusterProjectAreas &&
              clusterProjectAreas.length &&
              statusSelection != 'only2023' &&
              statusSelection != 'constructionStatus'
            "
          >
            <template v-for="(item, clusterIndex) in clusterProjectAreas">
              <vl-feature
                v-if="item && item.st_asgeojson"
                :id="item.projekt_id"
                :key="'c' + clusterIndex"
              >
                <vl-geom-multi-polygon
                  :coordinates="JSON.parse(item.st_asgeojson).coordinates"
                ></vl-geom-multi-polygon>
                <vl-style-box>
                  <vl-style-fill
                    :color="hex2rgba(item.color, 0.2)"
                  ></vl-style-fill>
                  <vl-style-stroke
                    :color="item.color"
                    :width="3"
                  ></vl-style-stroke>
                  <vl-style-text
                    :text="item.projekt_id.toString()"
                    font="bold 1rem sans-serif"
                    color="#ffffff"
                  ></vl-style-text>
                </vl-style-box>
              </vl-feature>
            </template>
          </template>

          <template
            v-else-if="
              clusterProjectAreas &&
              clusterProjectAreas.length &&
              statusSelection != 'only2023' &&
              statusSelection === 'constructionStatus'
            "
          >
            <template v-for="(item, clusterIndex) in clusterProjectAreas">
              <vl-feature
                v-if="item && item.st_asgeojson"
                :id="item.projekt_id"
                :key="'c' + clusterIndex"
              >
                <vl-geom-multi-polygon
                  :coordinates="JSON.parse(item.st_asgeojson).coordinates"
                ></vl-geom-multi-polygon>
                <vl-style-box>
                  <vl-style-fill
                    :color="hex2rgba(item.csColor, 0.2)"
                  ></vl-style-fill>
                  <vl-style-stroke
                    :color="item.csColor"
                    :width="3"
                  ></vl-style-stroke>
                  <vl-style-text
                    :text="item.projekt_id.toString()"
                    font="bold 1rem sans-serif"
                    color="#ffffff"
                  ></vl-style-text>
                </vl-style-box>
              </vl-feature>
            </template>
          </template>

          <template
            v-else-if="
              clusterProjectAreas &&
              clusterProjectAreas.length &&
              statusSelection === 'only2023'
            "
          >
            <template v-for="(item, clusterIndex) in clusterProjectAreas">
              <vl-feature
                v-if="item && item.st_asgeojson && item.vergabe_jahr == 2023"
                :id="item.projekt_id"
                :key="'c' + clusterIndex"
              >
                <vl-geom-multi-polygon
                  :coordinates="JSON.parse(item.st_asgeojson).coordinates"
                ></vl-geom-multi-polygon>
                <vl-style-box>
                  <vl-style-fill
                    :color="hex2rgba('#881111', 0.2)"
                  ></vl-style-fill>
                  <vl-style-stroke
                    :color="'#881111'"
                    :width="3"
                  ></vl-style-stroke>
                  <vl-style-text
                    :text="item.projekt_id.toString()"
                    font="bold 1rem sans-serif"
                    color="#ffffff"
                  ></vl-style-text>
                </vl-style-box>
              </vl-feature>
            </template>
          </template>
          <template v-if="bng && bngResult && bngResult.length">
            <vl-feature>
              <vl-geom-multi-line-string
                :coordinates="bngResult"
              ></vl-geom-multi-line-string>
              <vl-style-box>
                <vl-style-stroke
                  :color="'rgba(0,0,0,0.75)'"
                  :width="3"
                ></vl-style-stroke>
              </vl-style-box>
            </vl-feature>
            <vl-feature>
              <vl-geom-multi-line-string
                :coordinates="bngResult"
              ></vl-geom-multi-line-string>
              <vl-style-box>
                <vl-style-stroke :color="bngColor" :width="2"></vl-style-stroke>
              </vl-style-box>
            </vl-feature>
          </template>
          <template v-if="gasline && gaslineResult && gaslineResult.length">
            <vl-feature>
              <vl-geom-multi-line-string
                :coordinates="gaslineResult"
              ></vl-geom-multi-line-string>
              <vl-style-box>
                <vl-style-stroke
                  :color="'rgba(0,0,0,0.75)'"
                  :width="3"
                ></vl-style-stroke>
              </vl-style-box>
            </vl-feature>
            <vl-feature>
              <vl-geom-multi-line-string
                :coordinates="gaslineResult"
              ></vl-geom-multi-line-string>
              <vl-style-box>
                <vl-style-stroke
                  :color="gaslineColor"
                  :width="2"
                ></vl-style-stroke>
              </vl-style-box>
            </vl-feature>
          </template>
          <template v-if="backbone && backboneResult && backboneResult.length">
            <vl-feature>
              <vl-geom-multi-line-string
                :coordinates="backboneResult"
              ></vl-geom-multi-line-string>
              <vl-style-box>
                <vl-style-stroke
                  :color="'rgba(0,0,0,0.75)'"
                  :width="3"
                ></vl-style-stroke>
              </vl-style-box>
            </vl-feature>
            <vl-feature>
              <vl-geom-multi-line-string
                :coordinates="backboneResult"
              ></vl-geom-multi-line-string>
              <vl-style-box>
                <vl-style-stroke
                  :color="backboneColor"
                  :width="2"
                ></vl-style-stroke>
              </vl-style-box>
            </vl-feature>
          </template>
        </vl-map>
        <v-btn
          v-if="features && features.length"
          class="my-2"
          @click="clearFeatures"
        >
          <v-icon>mdi-delete-empty</v-icon> alle Polygone löschen
        </v-btn>
      </v-col>
      <v-col md="4">
        <v-card class="overview-sidebar">
          <v-tabs v-model="tab">
            <v-tab @change="hideClusterOverview"> Landkreise </v-tab>
            <v-tab @change="loadClusterOverview"> Vergabecluster </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-row class="pt-2">
                <v-col md="6">
                  <v-autocomplete
                    v-model="county"
                    :items="filteredCounties"
                    item-text="name"
                    item-value="name"
                    key-name="id"
                    label="Landkreis"
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col md="6">
                  <v-btn
                    color="primary"
                    @click="
                      getGoetelAreas(),
                        getKpis(),
                        getProjects(),
                        getCountyArea(),
                        (bng = false),
                        (gasline = false),
                        (backbone = false)
                    "
                  >
                    Informationen laden
                  </v-btn>
                </v-col>
              </v-row>
              <template v-if="kpis && kpis.length">
                <h3>
                  Kennzahlen für
                  <template v-if="county"> Landkreis {{ county }} </template>
                </h3>
                <KpiDisplay
                  v-if="projects && projects.length"
                  :kpi="kpis"
                  :project-length="projects.length"
                />
                <KpiDisplay
                  v-else-if="clusterProjectAreas && clusterProjectAreas.length"
                  :kpi="kpis"
                  :project-length="clusterProjectAreas.length"
                />
                <v-divider class="pb-2 mt-2" />
              </template>
              <template v-if="goetelAreas && goetelAreas.length">
                <h3>Infrastruktur</h3>
                <v-row>
                  <v-col md="6">
                    <table class="infrastructure-table">
                      <tr>
                        <td>
                          <v-checkbox
                            v-model="bng"
                            label="BNG-Line"
                            hide-details
                          ></v-checkbox>
                        </td>
                        <td>
                          <v-btn
                            fab
                            dark
                            :color="bngColor"
                            x-small
                            class="ml-2"
                            @click="changeColor('bng')"
                          >
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <v-checkbox
                            v-model="gasline"
                            label="Gasline"
                            hide-details
                          ></v-checkbox>
                        </td>
                        <td>
                          <v-btn
                            fab
                            dark
                            :color="gaslineColor"
                            x-small
                            class="ml-2"
                            @click="changeColor('gasline')"
                          >
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <v-checkbox
                            v-model="backbone"
                            label="Backbone"
                            hide-details
                          ></v-checkbox>
                        </td>
                        <td>
                          <v-btn
                            fab
                            dark
                            :color="backboneColor"
                            x-small
                            class="ml-2"
                            @click="changeColor('backbone')"
                          >
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </table>
                    <ColorPickerDialog
                      :color="colorDialogColor"
                      :showDialog="showColorDialog"
                      :type="colorDialogType"
                      @updateColor="updateColor"
                      @closeDialog="showColorDialog = false"
                    />
                  </v-col>
                  <v-col md="6">
                    <v-btn
                      color="primary"
                      class="mt-8"
                      @click="getInfrastructure"
                    >
                      Infrastruktur laden
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
              <template
                v-if="
                  distributionStatuses &&
                  distributionStatuses.length &&
                  mergedProjects &&
                  mergedProjects.length
                "
              >
                <v-divider class="pb-2 mt-2" />
                <h3>Vertriebsphasen</h3>
                <v-row style="padding: 12px 0">
                  <template
                    v-for="(status, statusIndex) in distributionStatuses"
                  >
                    <v-col
                      :key="'s' + statusIndex"
                      md="6"
                      style="padding: 0 12px"
                    >
                      <v-icon :color="status.color">
                        mdi-circle-slice-8
                      </v-icon>
                      <span style="font-size: 13px">
                        {{ status.description }}</span
                      >
                    </v-col>
                  </template>
                </v-row>
              </template>
            </v-tab-item>
            <v-tab-item>
              <v-switch
                v-model="showProjectSearch"
                label="Projektsuche"
                class="ml-5"
                @change="hideClusterOverview"
              ></v-switch>
              <v-row v-if="!showProjectSearch">
                <v-col md="6">
                  <v-select
                    v-model="vergabeClusterSelected"
                    :items="vergabeClusterCollection"
                    item-text="cluster_name"
                    item-value="cluster_id"
                    key-name="id"
                    label="Vergabecluster"
                    outlined
                    dense
                    @change="
                      getClusterNames('vertrieb', vergabeClusterSelected)
                    "
                  ></v-select>
                  <v-select
                    v-if="vertriebsClusterCollection"
                    v-model="vertriebsClusterSelected"
                    :items="vertriebsClusterCollection"
                    item-text="vertriebscluster_naming"
                    item-value="vertriebscluster_naming"
                    key-name="id"
                    label="Vertriebscluster"
                    outlined
                    dense
                  ></v-select>
                </v-col>
                <v-col md="6">
                  <v-btn
                    color="primary"
                    class="mt-5"
                    @click="
                      getClusterAreas(),
                        (county = undefined),
                        (goetelAreas = []),
                        (countyAreas = []),
                        (bng = false),
                        (gasline = false),
                        (backbone = false)
                    "
                  >
                    Informationen laden
                  </v-btn>
                </v-col>
              </v-row>
              <div v-else-if="showProjectSearch">
                <small style="color: #888; font-size: 12px"
                  >Projekt Suche:</small
                >
                <v-row>
                  <v-col md="12">
                    <v-combobox
                      ref="project"
                      v-model="projektItemIntern"
                      :items="projectItems"
                      :loading="loadingProjects"
                      hide-no-data
                      :search-input.sync="search"
                      :item-text="getProjectItemText"
                      label="Projekt (Name oder ID)"
                      :prepend-inner-icon="
                        projektItemIntern
                          ? 'mdi-map-check-outline'
                          : 'mdi-magnify'
                      "
                      append-icon=""
                      placeholder="Start typing to Search"
                      clearable
                      outlined
                      dense
                      return-object
                      key-name="project"
                    ></v-combobox>
                  </v-col>
                  <v-col
                    md="12"
                    v-if="projektItemIntern && clusterSearchObject"
                  >
                    <div class="cluster-info">
                      <v-simple-table v-if="clusterSearchObject" dense>
                        <tr v-if="projektItemIntern.state">
                          <td>Bundesland:</td>
                          <td>{{ projektItemIntern.state }}</td>
                        </tr>
                        <tr v-if="projektItemIntern.county">
                          <td>Bundesland:</td>
                          <td>{{ projektItemIntern.county }}</td>
                        </tr>
                        <tr v-if="clusterSearchObject.vergabecluster">
                          <td>Vergabecluster:</td>
                          <td>
                            <b>{{ clusterSearchObject.vergabecluster }}</b>
                          </td>
                        </tr>
                        <tr v-if="clusterSearchObject.vertriebscluster_naming">
                          <td>Vertriebscluster:</td>
                          <td>
                            <b>{{
                              clusterSearchObject.vertriebscluster_naming
                            }}</b>
                          </td>
                        </tr>
                        <tr v-else>
                          <td>Vertriebscluster:</td>
                          <td>
                            <span style="color: #ff0000"
                              >&#9888; keinem Vertriebscluster zugeordnet</span
                            >
                          </td>
                        </tr>
                      </v-simple-table>
                      <v-btn
                        color="primary"
                        class="float-right"
                        small
                        @click="
                          getClusterAreas(projektItemIntern.projektId),
                            (this.vergabeClusterSelected = undefined),
                            (this.vertriebsClusterSelected = undefined)
                        "
                      >
                        Cluster laden
                      </v-btn>
                    </div>
                  </v-col>
                  <v-col
                    md="12"
                    v-else-if="projektItemIntern && !clusterSearchObject"
                  >
                    <span
                      style="color: #ff0000; font-size: 12px; margin-top: -20px"
                      >&#9888; Projekt ist keinem Cluster zugeordnet</span
                    >
                  </v-col>
                </v-row>
              </div>
              <template
                v-if="clusterProjectAreas && clusterProjectAreas.length"
              >
                <h3>Infrastruktur</h3>
                <v-row>
                  <v-col md="6">
                    <table class="infrastructure-table">
                      <tr>
                        <td>
                          <v-checkbox
                            v-model="bng"
                            label="BNG-Line"
                            hide-details
                          ></v-checkbox>
                        </td>
                        <td>
                          <v-btn
                            fab
                            dark
                            :color="bngColor"
                            x-small
                            class="ml-2"
                            @click="changeColor('bng')"
                          >
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <v-checkbox
                            v-model="gasline"
                            label="Gasline"
                            hide-details
                          ></v-checkbox>
                        </td>
                        <td>
                          <v-btn
                            fab
                            dark
                            :color="gaslineColor"
                            x-small
                            class="ml-2"
                            @click="changeColor('gasline')"
                          >
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <v-checkbox
                            v-model="backbone"
                            label="Backbone"
                            hide-details
                          ></v-checkbox>
                        </td>
                        <td>
                          <v-btn
                            fab
                            dark
                            :color="backboneColor"
                            x-small
                            class="ml-2"
                            @click="changeColor('backbone')"
                          >
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </table>
                    <ColorPickerDialog
                      :color="colorDialogColor"
                      :showDialog="showColorDialog"
                      :type="colorDialogType"
                      @updateColor="updateColor"
                      @closeDialog="showColorDialog = false"
                    />
                  </v-col>
                  <v-col md="6">
                    <v-btn
                      color="primary"
                      class="mt-8"
                      @click="getInfrastructure"
                    >
                      Infrastruktur laden
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
              <template
                v-if="clusterProjectAreas && clusterProjectAreas.length"
              >
                <v-divider class="pb-2 mt-2" />
                <h3>Auswahl Statusanzeige</h3>
                <v-radio-group
                  v-if="clusterProjectAreas && clusterProjectAreas.length"
                  v-model="statusSelection"
                  row
                  class="status-selection"
                >
                  <v-radio
                    label="Vertriebsphase"
                    value="distributionStatus"
                  ></v-radio>
                  <v-radio
                    label="Baustatus"
                    value="constructionStatus"
                  ></v-radio>
                  <v-radio
                    label="nur Ausbaugebiete 2023"
                    value="only2023"
                  ></v-radio>
                </v-radio-group>
                <template
                  v-if="
                    distributionStatuses &&
                    distributionStatuses.length &&
                    statusSelection === 'distributionStatus'
                  "
                >
                  <h3>Vertriebsphasen</h3>
                  <!-- <v-switch
                    v-model="only2023"
                    class="ml-4"
                    label="nur Ausbaugebiete 2023"
                  ></v-switch> -->
                  <v-row style="padding: 12px 0">
                    <template
                      v-for="(status, statusIndex) in distributionStatuses"
                    >
                      <v-col
                        :key="'s' + statusIndex"
                        md="6"
                        style="padding: 0 12px"
                      >
                        <v-icon :color="status.color">
                          mdi-circle-slice-8
                        </v-icon>
                        <span style="font-size: 13px">
                          {{ status.description }}</span
                        >
                      </v-col>
                    </template>
                  </v-row>
                </template>
                <template
                  v-if="
                    distributionStatuses &&
                    distributionStatuses.length &&
                    statusSelection === 'constructionStatus'
                  "
                >
                  <h3>Baustatus</h3>
                  <!-- <v-switch
                    v-model="only2023"
                    class="ml-4"
                    label="nur Ausbaugebiete 2023"
                  ></v-switch> -->
                  <v-row style="padding: 12px 0">
                    <template
                      v-for="(status, statusIndex) in constructionStatuses"
                    >
                      <v-col
                        :key="'s' + statusIndex"
                        md="6"
                        style="padding: 0 12px"
                      >
                        <v-icon :color="status.color">
                          mdi-circle-slice-8
                        </v-icon>
                        <span style="font-size: 13px">
                          {{ status.description }}</span
                        >
                      </v-col>
                    </template>
                  </v-row>
                </template>
              </template>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
    <v-overlay :value="loading">
      <v-progress-circular
        :size="120"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <v-dialog
      v-model="detailsVisible"
      transition="dialog-bottom-transition"
      max-width="1200"
    >
      <v-card v-if="selected">
        <v-toolbar color="primary" dark>
          Details zu Projekt {{ selected }}
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="detailsVisible = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <ProjectDetails :project-id="selected" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { HTTP } from '../../main/httpClient';
import Vue from 'vue';
import proj4 from 'proj4';
import VueLayers from 'vuelayers';
import 'vuelayers/lib/style.css'; // needs css-loader

import { fromLonLat } from 'ol/proj';
import { register } from 'ol/proj/proj4';
// import Projection from 'ol/proj/Projection';
import NotificationObject from '@/main/NotificationObject.js';
import debounce from 'debounce';

Vue.use(VueLayers);

proj4.defs(
  'EPSG:25832',
  '+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'
);
register(proj4);
// register(proj4);

Vue.use(VueLayers, {
  // dataProjection: 'EPSG:4326'
  dataProjection: 'EPSG:25832'
});

export default {
  name: 'OverviewMap',
  components: {
    KpiDisplay: () =>
      import('@/components/constructionProjects/KpiDisplay.vue'),
    ProjectDetails: () =>
      import('@/components/constructionProjects/ProjectDetails.vue'),
    ColorPickerDialog: () =>
      import('@/components/constructionProjects/ColorPickerDialog.vue')
  },
  props: {
    selectedArea: {
      type: Number,
      required: false,
      default: undefined
    }
  },
  data: () => ({
    counties: [],
    vergabeClusterCollection: [],
    vergabeClusterSelected: undefined,
    vertriebsClusterCollection: [],
    vertriebsClusterSelected: undefined,
    areaType: 'all',
    projects: [],
    search: '',
    statusSelection: 'distributionStatus',
    vergabeClusterOverview: undefined,
    vergabeClusterPolygons: undefined,
    vertriebsClusterPolygons: undefined,
    clusterProjectAreas: undefined,
    distributionStatuses: [],
    constructionStatuses: [],
    dynamicStatus: {},
    tab: null,
    showProjectSearch: false,
    clusterSearchObject: undefined,
    county: undefined,
    kpis: undefined,
    showColorDialog: false,
    colorDialogColor: undefined,
    colorDialogType: undefined,
    bng: false,
    bngColor: '#64F100',
    gasline: false,
    gaslineColor: '#00E0F1',
    backbone: false,
    backboneColor: '#FF00A1',
    bngResult: [],
    gaslineResult: [],
    backboneResult: [],
    only2023: false,
    geoGoetelAddresses: undefined,
    osmAddresses: undefined,
    goetelAreas: undefined,
    countyArea: undefined,
    projection: 'EPSG:25832',
    bgcolor: '0x000000',
    center: [1083877.0610837992, 6676315.798540437],
    zoom: 8,
    rotation: 0,
    geolocPosition: undefined,
    clickCoordinate: undefined,
    selectedFeatures: [],
    selected: undefined,
    deviceCoordinate: undefined,
    features: [],
    detailsVisible: false,
    mapPanel: {
      tab: 'state'
    },
    panelOpen: true,
    mapVisible: true,
    drawControls: [
      {
        type: 'point',
        label: 'Draw Point',
        icon: 'map-marker'
      },
      {
        type: 'line-string',
        label: 'Draw LineString',
        icon: 'minus'
      },
      {
        type: 'polygon',
        label: 'Draw Polygon',
        icon: 'square-o'
      },
      {
        type: 'circle',
        label: 'Draw Circle',
        icon: 'circle-thin'
      },
      {
        type: undefined,
        label: 'Stop drawing',
        icon: 'times'
      }
    ],
    drawType: undefined,
    drawnFeatures: [],
    showDialog: false,
    loading: false,
    // pastURL: 'https://past.intern.goetel.de/api/',
    headers: {
      Authorization: 'Basic cGFzdGFkbWluOjhOcDkyalYxcGU=',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
    },
    projektItemIntern: undefined,
    projectItems: [],
    loadingProjects: false
  }),
  computed: {
    pastURL() {
      if (window.location.href.indexOf('https://localhost') < 0) {
        return (
          window.location.protocol + '//' + window.location.host + '/past-api/'
        );
      } else {
        // return 'https://past.intern.goetel.de/api/';
        return 'https://spot.intern.goetel.de/past-api/';
      }
    },
    pastURL2() {
      return 'https://spot.intern.goetel.de/past-api/';
      // return 'http://localhost:3200/api/';
    },
    filteredCounties() {
      let filtered = [];
      if (this.counties) {
        filtered = this.counties.filter(
          (item) =>
            item.federalState === 'Hessen' ||
            item.federalState === 'Niedersachsen'
        );
      }
      return filtered;
    },
    mergedProjects() {
      const resultArray = [];
      let obj = {};
      let poly = [];
      let borderColor;
      let fillColor;
      let baseColor;
      const hex2rgba = (hex, alpha = 1) => {
        const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
        return `rgba(${r},${g},${b},${alpha})`;
      };
      if (
        this.projects &&
        this.projects.length &&
        this.goetelAreas &&
        this.goetelAreas.length
      ) {
        this.projects.forEach((item) => {
          poly = this.goetelAreas.filter(
            (area) => area.projekt_id === item.projektId
          );
          if (
            item &&
            item.projektId &&
            item.projektId !== 142 &&
            item.name &&
            item.city &&
            item.district &&
            poly &&
            poly[0]
          ) {
            // borderColor = this.getRandomColor();
            // fillColor = hex2rgba(borderColor, 0.2);
            baseColor =
              item && item.distributionStatus && item.distributionStatus.color
                ? item.distributionStatus.color
                : '#cccccc';
            borderColor = baseColor;
            fillColor = hex2rgba(baseColor, 0.2);
            obj = {
              projektId: item.projektId,
              name: item.name,
              city: item.city,
              district: item.district,
              borderColor: borderColor,
              fillColor: fillColor,
              poly:
                poly && poly[0]
                  ? JSON.parse(poly[0].st_asgeojson).coordinates
                  : undefined // JSON.parse(poly).coordinates;
            };
            resultArray.push(obj);
          }
        });
      }
      return resultArray;
      // project 142 is on index 39 an causes errors
      // return resultArray.slice(38, 40);
    }
  },
  watch: {
    selectedFeatures: {
      deep: true,
      handler(newVal) {
        if (
          this.selectedFeatures &&
          this.selectedFeatures[0] &&
          !this.vergabeClusterOverview
        ) {
          // this.selected = this.mergedProjects.findIndex(item => item.projektId === this.selectedFeatures[0].id).id;
          this.selected = this.selectedFeatures[0].id;
        } else if (
          this.selectedFeatures &&
          this.selectedFeatures[0] &&
          this.vergabeClusterOverview
        ) {
          this.vergabeClusterSelected = this.selectedFeatures[0].id;
          this.getClusterAreas();
          this.county = undefined;
          this.goetelAreas = [];
          this.countyAreas = [];
          this.bng = false;
          this.gasline = false;
          this.backbone = false;
        }
      }
    },
    selectedProjectArea: {
      deep: true,
      handler(newVal) {
        this.$emit('changeSelection', newVal);
      }
    },
    selected() {
      this.detailsVisible = true;
    },
    projektItemIntern: {
      deep: true,
      handler(newValue) {
        console.log('newValue:', newValue);
        if (newValue && newValue.projektId) {
          this.getClusterByProject(newValue.projektId);
        }
      }
    },
    search(value) {
      if (!value) {
        return;
      }
      if (value.length >= 3) {
        debounce(this.makeSearch, 900)(value, this);
      }
    }
  },
  //   async mounted() {
  //     // await this.initiateMap();
  //   },
  async mounted() {
    // if (!this.selectedArea) {
    //   this.getGoetelAreas();
    // }
    this.counties = await this.getCollection('counties');
    this.distributionStatuses = await this.getCollection(
      'distributionStatuses'
    );
    this.constructionStatuses = await this.getCollection(
      'constructionStatuses'
    );
    this.vergabeClusterCollection = await this.getClusterNames('vergabe');
    // this.getProjectNames();
    // this.vertriebsClusterCollection = await this.getClusterNames('vertrieb');
  },
  methods: {
    generateMultilineArray(value) {
      const returnArray = [];
      if (Array.isArray(value)) {
        value.forEach((item) => {
          const deepArray = JSON.parse(item.st_asgeojson).coordinates;
          deepArray.forEach((deepItem) => {
            returnArray.push(deepItem);
          });
          // returnArray.push(JSON.parse(item.st_asgeojson).coordinates);
        });
      }
      return returnArray;
    },
    changeColor(type) {
      if (type === 'bng') {
        this.colorDialogColor = this.bngColor;
      } else if (type === 'gasline') {
        this.colorDialogColor = this.gaslineColor;
      } else if (type === 'backbone') {
        this.colorDialogColor = this.backboneColor;
      }
      this.colorDialogType = type;
      this.showColorDialog = true;
    },
    updateColor(color, type) {
      if (type === 'bng') {
        this.bngColor = color;
      } else if (type === 'gasline') {
        this.gaslineColor = color;
      } else if (type === 'backbone') {
        this.backboneColor = color;
      }
    },
    getCollection(collection) {
      // const pastURL = 'http://localhost:3000/api/';
      this.loading = true;
      return (
        HTTP
          // .get(pastURL + collection + '?where[federalState][equals]=Hessen', {
          .get('/past/' + collection)
          .then((response) => {
            this.loading = false;
            return response.data;
          })
          .catch((error) => {
            this.loading = false;
            console.log(error);
          })
      );
    },
    getClusterNames(type, allocation) {
      this.loading = true;
      let allocationString = '';
      if (allocation && parseInt(allocation) !== isNaN) {
        allocationString = '&allocation=' + allocation;
      }
      return HTTP.get('/past/geo/cluster-names?type=' + type + allocationString)
        .then((response) => {
          this.loading = false;
          if (type === 'vergabe') {
            return response.data;
          } else {
            const allArray = ['Alle'];
            this.vertriebsClusterCollection = [...allArray, ...response.data];
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    getProjects(collection) {
      this.loading = true;
      return HTTP.get('/past/projects?where[county][equals]=' + this.county)
        .then((response) => {
          this.loading = false;
          this.projects = response.data;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    getStatusList(collection) {
      this.loading = true;
      return HTTP.get('/past/distributionStatuses')
        .then((response) => {
          this.loading = false;
          this.distributionStatuses = response.data;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    convertPolygonBack(poly) {
      return JSON.parse(poly).coordinates;
    },
    fromLonLat(coordinate) {
      return fromLonLat(coordinate);
    },
    clearFeatures() {
      this.selectedFeatures = [];
      this.features = [];
    },
    deletePolygon(featureID) {
      this.features.splice(
        this.features.findIndex((item) => item.id === featureID),
        1
      );
    },
    getPolygonAddresses(poly) {
      const convertedPolygon = this.convertPolygon(poly);
      this.loading = true;
      let loadingIndex = 0;
      HTTP.get('/past/geo/project-addresses?poly=' + convertedPolygon)
        .then(
          (response) => (this.geoGoetelAddresses = response.data),
          (this.showDialog = true),
          (loadingIndex = loadingIndex + 1)
        )
        .catch(
          (error) => console.log(error),
          (loadingIndex = loadingIndex + 1)
        );

      HTTP.get('/past/geo/osm-addresses?poly=' + convertedPolygon)
        .then(
          (response) => (this.osmAddresses = response.data),
          (this.showDialog = true),
          (loadingIndex = loadingIndex + 1)
        )
        .catch(
          (error) => console.log(error),
          (loadingIndex = loadingIndex + 1)
        );

      if (loadingIndex >= 2) {
        this.loading = false;
      }
    },
    getKpis() {
      this.loading = true;
      HTTP.get('/past/geo/area-kpi?county=' + this.county)
        .then((response) => {
          this.kpis = response.data;
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.loading = false;
        });
    },
    getGoetelAreas() {
      this.loading = true;
      document.querySelector('canvas').style.filter = 'grayscale(10%)';
      HTTP.get('/past/geo/goetel-area-polygons?county=')
        .then((response) => {
          this.goetelAreas = response.data;
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.loading = false;
        });
    },
    getClusterAreas(projektId) {
      this.loading = true;

      let projektIdString = '';

      let distributionClusterNameString = '';

      let clusterName = '';

      if (this.showProjectSearch) {
        if (projektId && !isNaN(parseInt(projektId))) {
          projektIdString = '&projektId=' + projektId;
          clusterName = this.clusterSearchObject.vergabecluster;
          distributionClusterNameString =
            '&distributionClusterName=' +
            this.clusterSearchObject.vertriebscluster_naming;
        } else if (this.vergabeClusterSelected && !projektId) {
          clusterName = this.vergabeClusterSelected;
        } else {
          clusterName = '';
        }
      } else {
        if (
          this.vertriebsClusterSelected &&
          this.vertriebsClusterSelected !== 'Alle'
        ) {
          distributionClusterNameString =
            '&distributionClusterName=' + this.vertriebsClusterSelected;
        }

        clusterName = this.vergabeClusterSelected
          ? this.vergabeClusterSelected
          : '';
      }
      // const clusterName = this.vergabeClusterSelected
      //   ? this.vergabeClusterSelected
      //   : '';
      HTTP.get(
        '/past/geo/cluster-areas?clusterName=' +
          clusterName +
          distributionClusterNameString +
          projektIdString
      )
        .then((response) => {
          if (response && response.data && response.data.parentAreas) {
            this.goetelAreas = undefined;
            this.projects = undefined;
            this.countyArea = undefined;
            this.vergabeClusterPolygons =
              response.data.parentAreas.vergabeCluster;
            this.vertriebsClusterPolygons =
              response.data.parentAreas.vertriebscluster;
            this.clusterProjectAreas = response.data.childAreas;
            this.center = JSON.parse(
              response.data.parentAreas.center
            ).coordinates;
            this.zoom = 9;
          }
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.loading = false;
        });
    },
    getCountyArea() {
      this.loading = true;
      document.querySelector('canvas').style.filter = 'grayscale(10%)';
      HTTP.get('/past/geo/county-area?county=' + this.county)
        .then((response) => {
          this.countyArea = response.data.slice(0, 2500);
          this.vergabeClusterPolygons = undefined;
          if (response.data && response.data[0].center) {
            this.center = JSON.parse(response.data[0].center).coordinates;
            this.zoom = 10;
          }
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit(
            'addNotification',
            new NotificationObject(
              'warning',
              'Projeke nur im goetel VPN verfügbar'
            )
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getRandomColor() {
      var letters = '0123456789ABCDEF';
      var color = '#';
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    getInfrastructure() {
      if (this.bng) {
        this.getInfrastructureResult('bng');
      }
      if (this.gasline) {
        this.getInfrastructureResult('gasline');
      }
      if (this.backbone) {
        this.getInfrastructureResult('backbone');
      }
      // http://localhost:3200/api/geo/county-infrastructure?county=Göttingen&type=gasline
    },
    getInfrastructureResult(type) {
      this.loading = true;
      let requestString;
      if (this.county) {
        requestString = '&county=' + this.county;
      } else if (this.vergabeClusterSelected) {
        requestString = '&cluster=' + this.vergabeClusterSelected;
      }

      HTTP.get('/past/geo/area-infrastructure?type=' + type + requestString)
        .then((response) => {
          if (type === 'bng') {
            this.bngResult = this.generateMultilineArray(response.data);
          }
          if (type === 'gasline') {
            this.gaslineResult = this.generateMultilineArray(response.data);
          }
          if (type === 'backbone') {
            this.backboneResult = this.generateMultilineArray(response.data);
          }
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.loading = false;
        });
    },
    hex2rgba(hex, alpha = 1) {
      const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
      return `rgba(${r},${g},${b},${alpha})`;
    },
    // getProjectNames(searchString) {
    //   if (isNaN(parseInt(searchString)) || searchString.length >= 3) {
    //     HTTP.get('/past/geo/project-names?search=' + searchString)
    //       .then((response) => (this.projectItems = response.data))
    //       .catch((error) => console.log(error));
    //   }
    // },
    makeSearch: async (value, self) => {
      // Handle empty value
      if (!value) {
        self.projectItems = [];
        self.projektItemIntern = '';
      }
      // Items have already been requested
      if (self.locadingProjects) {
        return;
      }

      self.locadingProjects = true;

      if (isNaN(parseInt(value)) || value.length >= 3) {
        HTTP.get('/past/geo/project-names?search=' + value)
          .then((response) => (self.projectItems = response.data))
          .catch((error) => console.log(error))
          .finally(() => (self.locadingProjects = false));
      }
    },
    getProjectItemText(item) {
      let projectLabel;
      if (
        this.projectItems &&
        this.projectItems.length >= 1 &&
        item &&
        item.projektId
      ) {
        projectLabel = `${item.projektId},  ${item.name}, (${item.district}, ${item.city}, Landkreis ${item.county})`;
      }
      return projectLabel;
    },
    getClusterByProject(projektId) {
      // if (this.projektItemIntern && this.projektItemIntern.projektId) {
      HTTP.get('/past/geo/project-cluster-search?projektId=' + projektId)
        .then((response) => (this.clusterSearchObject = response.data))
        .catch((error) => console.log(error));
      // }
    },
    loadClusterOverview() {
      this.loading = true;

      HTTP.get('/past/geo/cluster-areas?all=true')
        .then((response) => {
          if (response && response.data && response.data.vergabeCluster) {
            this.goetelAreas = undefined;
            this.projects = undefined;
            this.countyArea = undefined;
            this.vergabeClusterOverview = response.data.vergabeCluster;
            this.vertriebsClusterPolygons = undefined;
            this.clusterProjectAreas = undefined;
            this.center = JSON.parse(response.data.center).coordinates;
            this.zoom = 9;
          } else {
            this.loading = false;
          }
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.loading = false;
        });
    },
    hideClusterOverview() {
      this.vergabeClusterOverview = undefined;
      this.vergabeClusterSelected = undefined;
      this.vergabeClusterSelected = undefined;
      this.vergabeClusterPolygons = undefined;
      this.vertriebsClusterPolygons = undefined;
      this.clusterProjectAreas = undefined;
    }
  }
};
</script>
<style scoped lang="scss">
.overview-sidebar {
  height: calc(100vh - 153px);
  margin: 10px;
  padding: 0 10px;
  position: relative;
  ::v-deep .v-text-field__details {
    display: none;
  }
  h3 {
    padding-bottom: 10px;
    font-size: 17px;
  }
}
.area-item {
  padding: 10px;
}
.infobox {
  margin-top: 15px;
  font-size: 14px;
  background: rgba(0, 0, 0, 0.1);
}
.area-item-container {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100% - 377px);
  position: relative;
  margin: 0px -10px -1px -10px;
}
.scroll-container {
  height: 100%;
}
.infrastructure-table {
  .v-input--selection-controls {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .v-btn--fab.v-size--x-small {
    height: 24px;
    width: 24px;
  }
}
.cluster-info {
  font-size: 12px;
  background: #ddd;
  padding: 5px;
  border-radius: 5px;
  margin-top: -20px;
  position: relative;
  .v-data-table {
    background: transparent;
    max-width: 70%;
  }
  td {
    padding-right: 15px;
  }
  .v-btn {
    position: absolute;
    bottom: 5px;
    right: 5px;
  }
}
.status-selection {
  margin-top: 0;
  .v-label {
    font-size: 14px;
  }
}
</style>

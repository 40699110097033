<template>
  <div><MainDashboard :title="'Provisionierungsprozesse'" /></div>
</template>

<script>
import MainDashboard from '@/dashboard/MainDashboard.vue';
export default {
  name: 'Dashboard',
  components: { MainDashboard },
  data: () => ({}),
  methods: {}
};
</script>
<style lang="scss" scoped></style>

<template>
  <div><HomePassedProcessesDashboard /></div>
</template>

<script>
import HomePassedProcessesDashboard from '@/dashboard/HomePassedProcessesDashboard.vue';
export default {
  name: 'Dashboard',
  components: { HomePassedProcessesDashboard },
  data: () => ({}),
  methods: {}
};
</script>
<style lang="scss" scoped></style>

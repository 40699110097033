<template>
  <div>
    <v-row>
      <v-col md="12">
        <Addressmaster />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Addressmaster from '@/components/constructionProjects/Addressmaster.vue';

export default {
  name: 'Addressmanagement',
  components: {
    Addressmaster
  },
  data: () => ({}),
  computed: {},
  watch: {},
  mounted() {
    // this.getAddresses();
  },
  methods: {}
};
</script>
<style lang="scss" scoped></style>

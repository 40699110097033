function companyIsValid(name) {
  return (
    name === null ||
    name === undefined ||
    name === '' ||
    /^[A-Za-zäöüÄÖÜß\-\s.]+$/.test(name)
  );
}
function nameIsValid(name) {
  return (
    name === null ||
    name === undefined ||
    name === '' ||
    /^[A-Za-zäöüÄÖÜß.\-\s]+$/.test(name)
  );
}

function emailIsValid(email) {
  return (
    email === null ||
    email === undefined ||
    email === '' ||
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  );
}

function phoneNumberIsValid(phoneNumber) {
  return (
    phoneNumber === null ||
    phoneNumber === undefined ||
    phoneNumber === '' ||
    /[+]*[0-9]+$/.test(phoneNumber)
  );
}

function ipv4IsValid(ipv4) {
  return (
    ipv4 === null ||
    ipv4 === undefined ||
    ipv4 === '' ||
    /^(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}$/gm.test(ipv4)
  );
}

function ipv6IsValid(ipv6) {
  return (
    ipv6 === null ||
    ipv6 === undefined ||
    ipv6 === '' ||
    /^(?:(?:[a-fA-F\d]{1,4}:){7}(?:[a-fA-F\d]{1,4}|:)|(?:[a-fA-F\d]{1,4}:){6}(?:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|:[a-fA-F\d]{1,4}|:)|(?:[a-fA-F\d]{1,4}:){5}(?::(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,2}|:)|(?:[a-fA-F\d]{1,4}:){4}(?:(?::[a-fA-F\d]{1,4}){0,1}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,3}|:)|(?:[a-fA-F\d]{1,4}:){3}(?:(?::[a-fA-F\d]{1,4}){0,2}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,4}|:)|(?:[a-fA-F\d]{1,4}:){2}(?:(?::[a-fA-F\d]{1,4}){0,3}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,5}|:)|(?:[a-fA-F\d]{1,4}:){1}(?:(?::[a-fA-F\d]{1,4}){0,4}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,6}|:)|(?::(?:(?::[a-fA-F\d]{1,4}){0,5}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,7}|:)))(?:%[0-9a-zA-Z]{1,})?$/gm.test(ipv6)
  );
}

export { companyIsValid, nameIsValid, emailIsValid, phoneNumberIsValid, ipv4IsValid, ipv6IsValid };

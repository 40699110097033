<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'ConstructionProjects',
  components: {},
  data: () => ({}),
  computed: {},
  watch: {},
  mounted() {
    // this.getAddresses();
  },
  methods: {}
};
</script>
<style lang="scss" scoped></style>
